const trackLabelsImage =
    `<svg width="625px" height="625px" viewBox="0 0 625 625" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>track labels</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="track-labels" fill="#FFFFFF" stroke="#737373">
            <rect id="Rectangle-Copy-27" stroke-width="12" x="6" y="6" width="613" height="613" rx="135"></rect>
            <g id="group" transform="translate(52.2778, 149.7248)" stroke-width="24">
                <path d="M448.444444,87.1189616 L448.444444,322.431462 L166.619398,322.431462 L19.2344421,204.775212 L166.619398,87.1189616 L448.444444,87.1189616 Z M244.028212,163.997434 C232.925565,163.997434 222.874656,168.493347 215.598982,175.760254 C208.318067,183.032394 203.815972,193.079106 203.815972,204.175675 C203.815972,215.272243 208.318067,225.318955 215.598982,232.591095 C222.874656,239.858002 232.925565,244.353915 244.028212,244.353915 C255.130858,244.353915 265.181767,239.858002 272.457442,232.591095 C279.738356,225.318955 284.240451,215.272243 284.240451,204.175675 C284.240451,193.079106 279.738356,183.032394 272.457442,175.760254 C265.181767,168.493347 255.130858,163.997434 244.028212,163.997434 Z" id="backdrop"></path>
                <path d="M495.232604,12 L495.232604,264.550423 L193.616935,264.550423 L35.4455183,138.275212 L193.616935,12 L495.232604,12 Z M168.133512,95.1660194 C156.441871,95.1660194 145.858581,99.8943121 138.197214,107.534302 C130.523695,115.18641 125.780417,125.758499 125.780417,137.434423 C125.780417,149.110347 130.523695,159.682435 138.197214,167.334543 C145.858581,174.974533 156.441871,179.702826 168.133512,179.702826 C179.825153,179.702826 190.408443,174.974533 198.06981,167.334543 C205.743329,159.682435 210.486607,149.110347 210.486607,137.434423 C210.486607,125.758499 205.743329,115.18641 198.06981,107.534302 C190.408443,99.8943121 179.825153,95.1660194 168.133512,95.1660194 Z" id="hero"></path>
            </g>
        </g>
    </g>
</svg>`

const trackLabelsImageHover =
    `<svg width="625px" height="625px" viewBox="0 0 625 625" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>track labels hover</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="track-labels-hover" stroke="#737373">
            <rect id="Rectangle-Copy-29" stroke-width="12" fill="#737373" x="6" y="6" width="613" height="613" rx="135"></rect>
            <g id="group" transform="translate(52.2778, 149.7248)" fill="#FFFFFF" stroke-width="24">
                <path d="M448.444444,85.1189616 L448.444444,320.431462 L166.619398,320.431462 L19.2344421,202.775212 L166.619398,85.1189616 L448.444444,85.1189616 Z M244.028212,161.997434 C232.925565,161.997434 222.874656,166.493347 215.598982,173.760254 C208.318067,181.032394 203.815972,191.079106 203.815972,202.175675 C203.815972,213.272243 208.318067,223.318955 215.598982,230.591095 C222.874656,237.858002 232.925565,242.353915 244.028212,242.353915 C255.130858,242.353915 265.181767,237.858002 272.457442,230.591095 C279.738356,223.318955 284.240451,213.272243 284.240451,202.175675 C284.240451,191.079106 279.738356,181.032394 272.457442,173.760254 C265.181767,166.493347 255.130858,161.997434 244.028212,161.997434 Z" id="backdrop"></path>
                <path d="M495.232604,12 L495.232604,264.550423 L193.616935,264.550423 L35.4455183,138.275212 L193.616935,12 L495.232604,12 Z M168.133512,95.1660194 C156.441871,95.1660194 145.858581,99.8943121 138.197214,107.534302 C130.523695,115.18641 125.780417,125.758499 125.780417,137.434423 C125.780417,149.110347 130.523695,159.682435 138.197214,167.334543 C145.858581,174.974533 156.441871,179.702826 168.133512,179.702826 C179.825153,179.702826 190.408443,174.974533 198.06981,167.334543 C205.743329,159.682435 210.486607,149.110347 210.486607,137.434423 C210.486607,125.758499 205.743329,115.18641 198.06981,107.534302 C190.408443,99.8943121 179.825153,95.1660194 168.133512,95.1660194 Z" id="hero"></path>
            </g>
        </g>
    </g>
</svg>`

export { trackLabelsImage, trackLabelsImageHover }
