// Contains "normalization vector index" (nvi values) for legacy (v8) hosted files from aidenlab, ENCODE, and 4DN.
// These values can speed up initialization by a few seconds.
export default {
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Fcombined.hic": "54386046426,55860",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Fprimary.hic": "33860030033,37504",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Freplicate.hic": "30849652794,55832",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Fmaternal.hic": "1380542661,17185",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Fpaternal.hic": "1389001777,17185",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2Fcombined.hic": "13433880319,35723",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC001.hic": "3409347253,35975",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC002.hic": "4529947083,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC003.hic": "6894962500,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC004.hic": "3395593338,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC005.hic": "5204128636,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC006.hic": "3278363811,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC007.hic": "3585547340,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC008.hic": "4134197273,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC009.hic": "2851728310,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC010.hic": "1754216102,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC011.hic": "1716829574,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC012.hic": "4367980375,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC013.hic": "2309654671,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC014.hic": "5324950266,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC015.hic": "3132333594,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC016.hic": "3159154704,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC017.hic": "3262554627,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC018.hic": "3014216364,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC019.hic": "5025753246,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC020.hic": "5861584507,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC021.hic": "3542263275,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC022.hic": "5061949378,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC023.hic": "4816423919,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC024.hic": "2683781104,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC025.hic": "6522701781,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC026.hic": "6748846520,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC027.hic": "5642693007,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC028.hic": "2098921691,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC029.hic": "3886454027,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fagar%2FHIC030.hic": "2288984204,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fagar%2FHIC031.hic": "2115342419,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fpellet%2FHIC032.hic": "2063314324,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fsupernatant%2FHIC033.hic": "1933908457,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fdilution%2FHIC034.hic": "13555514595,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fdilution%2FHIC035.hic": "6129650900,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fdilution%2FHIC036.hic": "4307445019,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fdilution%2FHIC037.hic": "9375139867,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fdilution%2Fcombined.hic": "11664249584,33929",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Fcombined_DpnII.hic": "7003537290,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC038.hic": "2099825544,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC039.hic": "2096900138,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC040.hic": "2593642141,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC041.hic": "2263919098,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC042.hic": "2684774693,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2Fcombined_noXlink.hic": "5574807456,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC043.hic": "2986114347,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC044.hic": "1916378621,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC045.hic": "746463126,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC046.hic": "2338476164,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC047.hic": "1167496250,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC048.hic": "2231690794,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fin-situ%2FHIC049.hic": "2782308678,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC050.hic": "3633531346,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC051.hic": "4063486444,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC052.hic": "929756278,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC053.hic": "2489657204,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC054.hic": "4036419444,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC055.hic": "4284185549,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fin-situ%2FHIC056.hic": "4410546240,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fimr90%2Fdilution%2FHIC057.hic": "1491998259,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2Fcombined.hic": "7266425111,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2FHIC058.hic": "6532635593,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2FHIC059.hic": "400684530,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2FHIC060.hic": "645352187,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2FHIC061.hic": "736291871,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2FHIC062.hic": "762731037,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fin-situ%2FHIC063.hic": "682917431,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhmec%2Fdilution%2FHIC064.hic": "4550171307,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fnhek%2Fin-situ%2Fcombined.hic": "11897184911,35789",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fnhek%2Fin-situ%2FHIC065.hic": "5425943567,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fnhek%2Fin-situ%2FHIC066.hic": "5942869818,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fnhek%2Fin-situ%2FHIC067.hic": "5191049261,35647",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fnhek%2Fdilution%2FHIC068.hic": "2713318801,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2Fcombined.hic": "12641555389,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2FHIC069.hic": "5630177461,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2FHIC070.hic": "5926611269,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2FHIC071.hic": "2486191351,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2FHIC072.hic": "2281343366,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2FHIC073.hic": "2173074239,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fk562%2Fin-situ%2FHIC074.hic": "2463700999,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fkbm7%2Fin-situ%2Fcombined.hic": "14661922519,35639",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fkbm7%2Fin-situ%2FHIC075.hic": "3685310515,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fkbm7%2Fin-situ%2FHIC076.hic": "5833658487,35891",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fkbm7%2Fin-situ%2FHIC077.hic": "2406346486,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fkbm7%2Fin-situ%2FHIC078.hic": "5241454987,35919",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fkbm7%2Fin-situ%2FHIC079.hic": "6297234263,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhuvec%2Fin-situ%2Fcombined.hic": "9193858096,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhuvec%2Fin-situ%2FHIC080.hic": "5171405536,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhuvec%2Fin-situ%2FHIC081.hic": "3489767688,35891",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhuvec%2Fin-situ%2FHIC082.hic": "4243339375,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhuvec%2Fdilution%2FHIC083.hic": "1497495406,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhela%2Fin-situ%2Fcombined.hic": "7923332224,35593",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhela%2Fin-situ%2FHIC084.hic": "867699123,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhela%2Fin-situ%2FHIC085.hic": "3669518514,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhela%2Fin-situ%2FHIC086.hic": "5306474712,35595",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhela%2Fin-situ%2FHIC087.hic": "1490963755,36397",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2Fcombined.hic": "8956382853,50248",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC088.hic": "1874185366,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC089.hic": "1301165236,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC090.hic": "1105246282,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC091.hic": "1338325770,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC092.hic": "1307918730,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC093.hic": "755136918,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC094.hic": "1606583694,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC095.hic": "2039711245,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC096.hic": "1909573053,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC097.hic": "1826710712,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC098.hic": "1998793575,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC099.hic": "1007740962,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC100.hic": "736464120,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC101.hic": "980505082,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fin-situ%2FHIC102.hic": "1116083646,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fdilution%2FHIC103.hic": "1096994310,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fch12-lx-b-lymphoblasts%2Fdilution%2FHIC104.hic": "841063882,32102",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC105.hic": "107742864,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC106.hic": "134295912,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC107.hic": "110228014,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC108.hic": "102761401,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC109.hic": "100461491,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC110.hic": "88828472,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC111.hic": "81471064,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC112.hic": "92297035,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC113.hic": "65503916,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC114.hic": "49181188,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC115.hic": "46471102,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC116.hic": "64284432,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC117.hic": "60434076,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC118.hic": "45248563,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC119.hic": "60288782,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC120.hic": "136888414,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC121.hic": "125009003,33681",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC122.hic": "154011688,34127",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC123.hic": "177663372,35293",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC124.hic": "382186226,35245",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC125.hic": "158160717,35271",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC127.hic": "110261177,34659",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC128.hic": "246131875,35509",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC129.hic": "260160119,35667",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC130.hic": "117479562,34681",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC131.hic": "81508929,34673",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC132.hic": "105959102,34895",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC133.hic": "91504812,33451",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC134.hic": "52222546,33737",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC135.hic": "57763085,34159",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC136.hic": "50618489,33731",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC137.hic": "193163606,33363",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC138.hic": "229876152,33777",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC139.hic": "201783666,33571",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC140.hic": "281163862,34125",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC141.hic": "275969727,33935",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC142.hic": "275020431,34125",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC144.hic": "223834810,34177",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC145.hic": "296632653,33937",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC146.hic": "290945216,33961",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC147.hic": "234459964,34231",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC148.hic": "295457850,34039",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC149.hic": "113569632,35695",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC150.hic": "304381897,33963",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC151.hic": "289314545,33805",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC152.hic": "284320182,33803",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC153.hic": "401393947,34659",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC154.hic": "188065488,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC155.hic": "299157119,33967",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC156.hic": "279289244,33787",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC157.hic": "254852119,33917",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC158.hic": "195969660,33477",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC159.hic": "185720388,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC160.hic": "227709279,33599",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC161.hic": "157225076,33209",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC162.hic": "105703444,32033",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC163.hic": "122665658,32121",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC164.hic": "228048231,33761",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC165.hic": "114440819,32011",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC166.hic": "285147558,33891",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC167.hic": "363116754,33943",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC168.hic": "119592158,31937",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC169.hic": "236853872,33785",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC170.hic": "195187639,33395",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC171.hic": "208044058,33309",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC172.hic": "144645916,32877",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC173.hic": "119115941,32203",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC175.hic": "178030481,33525",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC176.hic": "117220136,32169",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC177.hic": "128586196,32659",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC178.hic": "191419141,33629",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC179.hic": "141556748,32959",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC180.hic": "199825102,33185",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC181.hic": "301022688,33783",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC182.hic": "185384453,33205",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC183.hic": "202189264,36367",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC184.hic": "37464475,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC185.hic": "161938278,36099",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC186.hic": "133853166,36179",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC188.hic": "128068030,36237",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC189.hic": "148053886,36177",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC190.hic": "198946958,35803",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC191.hic": "226584564,36063",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC192.hic": "194412797,36341",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC193.hic": "176408194,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC194.hic": "207215649,36055",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC195.hic": "175185530,36315",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC196.hic": "168969337,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC197.hic": "121231252,36339",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC198.hic": "92713752,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC199.hic": "105130500,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC200.hic": "114526350,36479",
    "hicfiles.s3.amazonaws.com%2Fmiseq%2Frh2014%2FHIC201.hic": "122689717,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fhap1%2Fin-situ%2Fcombined.hic": "9614983328,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fctbp_8_4_17%2Fall_intra_megabase_michrom.hic": "154205644,10960",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2Fdel-Xa-combined.hic": "8843305329,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FdelXa-chrX-diploid.hic": "11173520,1498",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FWT-combined.hic": "13365182403,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FWT-chrX-diploid.hic": "17343459,1498",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC002.hic": "4279298739,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC003.hic": "3027365532,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC004.hic": "2896130835,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC005.hic": "4717830071,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC006.hic": "5066210154,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC007.hic": "3069516128,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2Fdel-Xi-combined.hic": "14551096423,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FdelXi-chrX-diploid.hic": "17362477,1498",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC008.hic": "2572386655,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC009.hic": "2935931663,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC010.hic": "2019085070,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC011.hic": "6087610814,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC012.hic": "2809618194,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frpe1%2FDarrowHuntley-2015%2FHIC013.hic": "2572386655,36479",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2Fcombined.hic": "6950423609,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2Fpaternal.hic": "220592749,15691",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2Fmaternal.hic": "323233095,15691",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2FHIC014.hic": "3183235800,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2FHIC015.hic": "1739539801,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2FHIC016.hic": "1105912758,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2FHIC017.hic": "1060773319,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2FHIC018.hic": "1203679874,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fpatski%2FDarrowHuntley-2015%2FHIC019.hic": "3321955269,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Frhesus%2FDarrowHuntley-2015%2FHIC020.hic": "7108980626,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fgm12878%2Fcola%2FDarrowHuntley-2015%2FHIC021.hic": "3906448676,34955",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fassembly%2Faedes%2Fcombined.hic": "390780490,2245",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fassembly%2Fhs2-hic.hic": "1960284186,33561",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2Fassembly%2FCpipJ3.hic": "766279097,4381",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fphanstiel%2Fupdated_O%2FSnyder_O.hic": "8046980555,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fphanstiel%2FA_inter.hic": "10986005145,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2Fcombined.hic": "30439217680,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2Fcombined.hic": "33255540403,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Fdeep%2F20min_withdraw_combined.hic": "11078436933,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Fdeep%2F40min_withdraw_combined.hic": "11031830038,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Fdeep%2F60min_withdraw_combined.hic": "10312725340,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Fdeep%2F180min_withdraw_combined.hic": "10038610214,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC001.hic": "8004335785,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC002.hic": "8453215933,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC003.hic": "9118406189,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC004.hic": "5973342894,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC005.hic": "6846576837,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC006.hic": "6075148017,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Funsynchronized%2FHIC007.hic": "8192366992,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC008.hic": "6844159653,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC009.hic": "7282063059,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC010.hic": "10410578833,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC011.hic": "8192366992,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC012.hic": "7223422850,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC013.hic": "7435986997,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Funsynchronized%2FHIC014.hic": "9120113605,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Fsynchronized%2Fcombined.hic": "4170577904,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Fsynchronized%2FHIC015.hic": "1504084990,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Fsynchronized%2FHIC016.hic": "1686244500,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Fsynchronized%2FHIC017.hic": "1804802410,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Funtreated%2Fsynchronized%2FHIC018.hic": "1533942067,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Fsynchronized%2Fcombined.hic": "4572390198,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Fsynchronized%2FHIC019.hic": "1543609666,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Fsynchronized%2FHIC020.hic": "1814133288,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Fsynchronized%2FHIC021.hic": "1942698243,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftreated_6hr%2Fsynchronized%2FHIC022.hic": "1873587413,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Funtreated%2FRao-2017-HIC049.hic": "405127557,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Funtreated%2FRao-2017-HIC060.hic": "326681741,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2Funtreated%2FRao-2017-HIC061.hic": "300908673,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_20min%2FRao-2017-HIC050.hic": "481758242,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_20min%2FRao-2017-HIC051.hic": "417577401,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_40min%2FRao-2017-HIC052.hic": "419353227,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_40min%2FRao-2017-HIC053.hic": "411554953,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_60min%2FRao-2017-HIC054.hic": "386655891,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_60min%2FRao-2017-HIC055.hic": "402170701,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_240min%2FRao-2017-HIC057.hic": "735906308,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min%2FRao-2017-HIC058.hic": "443789827,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min%2FRao-2017-HIC059.hic": "491422615,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min%2FRao-2017-HIC062.hic": "320261375,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min%2FRao-2017-HIC063.hic": "306590499,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_20min%2FRao-2017-HIC032S.hic": "367051742,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_20min%2FRao-2017-HIC033S.hic": "344555339,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_40min%2FRao-2017-HIC036S.hic": "349392947,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_40min%2FRao-2017-HIC037S.hic": "317714554,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_60min%2FRao-2017-HIC040S.hic": "266786397,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_60min%2FRao-2017-HIC041S.hic": "279445530,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_180min%2FRao-2017-HIC044S.hic": "898418269,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_180min%2FRao-2017-HIC045S.hic": "961006347,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_360min%2FRao-2017-HIC064.hic": "833576408,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_360min%2FRao-2017-HIC065.hic": "840881418,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_1080min%2FRao-2017-HIC066.hic": "980958864,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_1080min%2FRao-2017-HIC067.hic": "882023129,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_1440min%2FRao-2017-HIC068.hic": "1110292630,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fdegron%2Ftime_course%2FAuxin_treated_360min_withdraw_1440min%2FRao-2017-HIC069.hic": "1001747023,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FCP190_HiChIP.hic": "144107588,6976",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FH3K27ac_HiChIP_combined.hic": "43171370,5233",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FH3K27me3_HiChIP_combined.hic": "42906472,5233",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FPol2_ChIAPET.hic": "41992430,6976",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FPol2S2_HiChIP.hic": "145842743,6976",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FArabidopsis_Wang2015_Liu2016.hic": "1134391078,4984",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FElegans_Crane2015.hic": "387127568,8758",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FNcrassa_Galazka2016.hic": "26555511,27725",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fexternal%2Frowley_nichols_mol_cell_2017%2FPfalciparum_trophozoite_Ay2014.hic": "15640893,13948",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FE-Me-A.hic": "6897251,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FE-Me-B.hic": "4959088,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FE-Mp-A.hic": "5232286,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FE-Mp-B.hic": "5105292,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FH-Me-A.hic": "6900072,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FH-Me-B.hic": "8653258,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FH-Mp-A.hic": "7999058,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fduan%2FH-Mp-B.hic": "8774657,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdekker%2F4dn%2Fh1hesc.hic": "23671753603,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdekker%2F4dn%2Fh1hesc_rep1.hic": "23671754721,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdekker%2F4dn%2Fh1hesc_rep2.hic": "22269909910,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdekker%2F4dn%2Fhffc6_rep1.hic": "19016661622,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flieberman_aiden_van_berkum%2FGM06990_NcoI.hic": "480725203,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flieberman_aiden_van_berkum%2F302NH.hic": "99948617,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flieberman_aiden_van_berkum%2FK562.hic": "470263605,36151",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flieberman_aiden_van_berkum%2FGM06990_HindIII.hic": "293706559,36289",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flieberman_aiden_van_berkum%2FGM06990_rep_HindIII.hic": "309673862,36087",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Fcanis-lupus-rep1.hic": "1531151371,56905",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Fcanis-lupus-rep2.hic": "2851437676,56905",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Fmouse-rep1.hic": "582052544,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Fmacaque-rep1.hic": "698509182,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Fmacaque-rep2.hic": "461410393,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Frabbit-rep1.hic": "691045338,33561",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frudan%2Frabbit-rep2.hic": "668888336,33561",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsexton%2Fcombined.hic": "50650087,4486",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fkalhor%2Ftcc-hindiii.hic": "1071142023,16500",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fkalhor%2Ftcc-mboi.hic": "1508851093,16448",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fkalhor%2Fnon-tcc-hindiii.hic": "395179229,36093",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fkalhor%2Ftcc-combined.hic": "1050423847,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdixon%2Fmm9-hindiii%2Fsplit-read-run.hic": "9599984876,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdixon%2Fhesc-hindiii.hic": "1952555632,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdixon%2Fimr90-hindiii.hic": "9691260435,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdixon%2Fmm9-cortex.hic": "3712072953,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzhang%2Fatm-i-scei-chr18.hic": "2083350075,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzhang%2Fatm-i-scei-chr2.hic": "3362049644,16360",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzhang%2Fatm-i-scei-chr7.hic": "1658399854,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzhang%2Fwt-i-scei-chr15.hic": "866691249,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzhang%2Fwt-i-scei-chr2.hic": "3304782795,16386",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-1.hic": "23673506,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-2.hic": "26873280,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-3.hic": "31066362,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-4.hic": "16807724,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-5.hic": "28665065,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-6.hic": "13124158,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-7.hic": "15118945,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-8.hic": "68316814,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-9.hic": "18965101,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2Fcell-10.hic": "17020666,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Fcombined.hic": "6526601242,36427",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Frep1.hic": "1861562891,36401",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Frep2.hic": "3061013635,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Frep3.hic": "1564588211,35969",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Frep4.hic": "1255543175,36153",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Frep5.hic": "974683253,36179",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90%2Frep6.hic": "1040805629,36053",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90-tnf-alpha%2Fcombined.hic": "8424080116,36401",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90-tnf-alpha%2Frep1.hic": "2697976702,36295",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90-tnf-alpha%2Frep3.hic": "1718610467,36159",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90-tnf-alpha%2Frep4.hic": "2029519405,36101",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90-tnf-alpha%2Frep5.hic": "985310855,36069",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjin%2Fimr90-tnf-alpha%2Frep6.hic": "1418750480,35967",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fcheng%2Fmhh-call-4-cell-line.hic": "538380253,35351",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fcheng%2Fprimary-b-all.hic": "773764191,35793",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fcheng%2Fnormal-b-cell-line.hic": "336271459,35005",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fcheng%2Frl-cell-line.hic": "484807998,35351",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fhou%2Fkc167-biorep.hic": "135932435,8758",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fhou%2Fkc167-techrep1.hic": "160355961,8758",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fhou%2Fkc167-techrep2.hic": "188809887,8758",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flin%2Fpre-prob-egs.hic": "2237792858,15483",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flin%2Fprob-egs.hic": "5744720377,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Flin%2Fprob-fa.hic": "2174866215,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fli%2Fhct116-rnapii-pilot.hic": "396366166,35999",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fli%2Fhela-rnapii-pilot.hic": "882516759,35107",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fli%2Fk562-rnapii-pilot.hic": "1131576189,35969",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fli%2Fk562-rnapii-saturated.hic": "571583828,36291",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fli%2Fmcf7-rnapii-saturated.hic": "1282547841,36035",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fli%2Fnb4-rnapii-pilot.hic": "761614686,34861",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fjung%2Fmouse_sperm.hic": "1730231642,29184",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ftang%2Fgm12878.hic": "1953518212,20505",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frutledge%2FSRR1791299.hic": "5723484,11956",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frutledge%2FSRR1791297.hic": "16534743,23348",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-rad21cv-hrv.hic": "5094525579,36289",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-rad21cv-hrv-rep1.hic": "2574358912,36373",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-rad21cv-hrv-rep2.hic": "3417099433,36243",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-rad21cv-tev.hic": "4785157552,36323",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-rad21cv-tev-rep1.hic": "2648268465,36321",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-rad21cv-tev-rep2.hic": "2994727980,36107",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-sirna-ctcf.hic": "5391791815,36063",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-sirna-ctcf-rep1.hic": "3325842095,36269",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-sirna-ctcf-rep2.hic": "3096215920,36167",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-sirna-ctrl.hic": "3830902383,36167",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-sirna-ctrl-rep1.hic": "1346712252,36079",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fzuin%2Fhek293t-sirna-ctrl_rep2.hic": "3098067367,36271",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fay%2Fp-falciparum-3d7-0h-hiseq.hic": "38174042,21889",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fay%2Fp-falciparum-3d7-18h-hiseq.hic": "26506099,21889",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fay%2Fp-falciparum-3d7-18h-hiseq-non-crosslinked_control.hic": "3545496,21889",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fay%2Fp-falciparum-3d7-36h-hiseq.hic": "70754194,21889",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3%2Fg1mid-r1.hic": "200863476,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3%2Fm-r1.hic": "249021190,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3%2Fm-r2.hic": "212341157,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3ccl2p2%2Fg1-0-25fa.hic": "583572265,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3ccl2p2%2Fg1-1fa.hic": "354502306,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3ccl2p2%2Fm-0-25fa.hic": "149308791,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3ccl2p2%2Fm-98percent.hic": "778175225,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhelas3ccl2p2%2Fm-1fa.hic": "158091047,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhff1%2Fcchic-hff1-ns-r1.hic": "801670039,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fhff1%2Fcchic-hff1-m-r1.hic": "875922086,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fk562%2Fm-r1.hic": "130959943,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnaumova%2Fk562%2Fm-r2.hic": "237861106,17185",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmAST-WT.hic": "496104039,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmAST-WT-adv-cre.hic": "438608517,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmAST-floxed-rep1.hic": "446205712,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmAST-floxed-rep2.hic": "386354767,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmAST-deleted-rep1.hic": "621998722,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmAST-deleted-rep2.hic": "583840829,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmNSC-floxed-rep1.hic": "801290988,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmNSC-floxed-rep2.hic": "819406658,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmNSC-deleted-rep1.hic": "792825269,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmNSC-deleted-rep2.hic": "746189884,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmNSC-WT.hic": "309353372,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fsofueva%2FmNSC-WT-OHT.hic": "274229679,30643",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fseitan%2FTcell-Rad21KO-R1.hic": "1490362251,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fseitan%2FTcell-Rad21KO-R3.hic": "2403664421,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fseitan%2FTcell-Rad21WT-R1.hic": "1377124538,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fseitan%2FTcell-Rad21WT-R3.hic": "2646201369,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fmoissiard%2Fcrh6-mutant.hic": "105261071,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fmoissiard%2FWT.hic": "127506388,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fgrob%2FCol.hic": "153638500,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fgrob%2Fcrwn1.hic": "211213220,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fgrob%2Fcrwn4.hic": "134236837,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fxie%2Fprimary.hic": "92936173,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2F6wt-control6wt337.hic": "318737891,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2F6wt-control6wt67.hic": "348123956,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2F6wt337.hic": "251107279,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2F6wt67.hic": "351840994,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2FCol0.hic": "345969497,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2Fclf28-swn7.hic": "299585025,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2Fcmt3-11.hic": "253427706,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2Fddm1-2.hic": "303626924,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2Fmet1-3.hic": "347697973,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Ffeng%2Fsuvh4-suvh5-suvh6.hic": "263122019,10217",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FDNase-HiC-WG-K562.hic": "892955840,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FDNaseHiC-WG-H1.hic": "696922695,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FtargetedDNaseHiC-lincRNA-K562-rep1.hic": "341917716,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FtargeredDNaseHiC-lincRNA-K562-rep2.hic": "214841185,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FtargetedDNaseHiC-lincRNA-H1-rep1.hic": "616326137,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FtargetedDNaseHiC-lincRNA-H1-rep2.hic": "579654013,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FtargetedDNaseHiC-pe-H1-rep1.hic": "519810544,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fma%2FtargetedDNaseHiC-pe-K562-rep1.hic": "199553123,18679",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frowley%2FKc167_DpnII.hic": "1319373594,6976",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frowley%2FKc167_HinfI.hic": "1465493873,6976",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Frowley%2FKc167_DpnII_HinfI_combo.hic": "753062558,5814",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fbarutcu%2FMCF-10A.hic": "2784956115,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fbarutcu%2FMCF-7.hic": "2700457411,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fbatullin%2Fsperm.hic": "501052169,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fminajigi%2FWT_hiC_rep1.hic": "1450697530,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fminajigi%2FWT_hiC_rep2.hic": "1189371558,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fminajigi%2Fcombined.hic": "2262007875,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fminajigi%2Finactive.hic": "4444564,751",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fminajigi%2Factive.hic": "3993495,751",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fpatski_rep1.hic": "1650838357,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fpatski_rep2.hic": "1258038606,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fpatski.hic": "499827296,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fpatski_active.hic": "79175714,15691",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fpatski_inactive.hic": "98947881,15691",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fbrain_dnase.hic": "4298088401,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fbrain_in_situ_dnase.hic": "609321852,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fbrain.hic": "1028196506,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fbrain_active.hic": "79175714,15691",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fdeng%2Fbrain_inactive.hic": "98947881,15691",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fle%2FBglII_rep1.hic": "1283306,1463",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fle%2FBglII_rep2.hic": "1263227,1463",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fle%2FNcoI_rep1.hic": "4380906,1463",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Feagen%2FGSE89112_Kc167combined.hic": "1829549643,25624",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Feagen%2FGSE89112_Kc167combined_randomized.hic": "1859742654,25624",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fmumbach%2FGSE80820_HiChIP_GM_cohesin.hic": "1231264781,17932",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fmumbach%2FGSE80820_HiChIP_mES_cohesin_all.hic": "1152008628,16438",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fmumbach%2FGSE80820_HiChIP_mES_Oct4.hic": "912828146,16438",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fwapl_hic%2FSCC4KO.hic": "1767906138,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fwapl_hic%2FDKO.hic": "1487228787,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fwapl_hic%2FWaplKO_1.14.hic": "1189751862,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fwapl_hic%2FWaplKO_3.3.hic": "1155057696,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fwapl_hic%2FWT.hic": "1522401568,18679",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fskfuwi4fnsjkdf9jekrw2%2Fmega_Liverpool%2Fmega.hic": "2612171975,4381",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fskfuwi4fnsjkdf9jekrw2%2FHIC2836_Liverpool1%2FLiverpool1.hic": "191729195,2245",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fskfuwi4fnsjkdf9jekrw2%2FHIC2861_Liverpool2%2FLiverpool2.hic": "1637096030,4381",
    "s3.amazonaws.com%2Fhicfiles%2Fhiseq%2Fskfuwi4fnsjkdf9jekrw2%2FHIC2862_Liverpool3%2FLiverpool3.hic": "1160045602,4381",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fgoodell%2FHSPC.hic": "11857786247,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fgoodell%2FHSPC_CanyonDel.hic": "8139168836,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fgoodell%2Ftcell.hic": "9871547081,36479",
    "s3.amazonaws.com%2Fhicfiles%2Fexternal%2Fgoodell%2Fep.hic": "17479335373,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fnagano%2FTh1_ensemble.hic": "1690592868,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fbonev%2FES_mapq30.hic": "32930498831,32386",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fbonev%2FNPC_mapq30.hic": "55053730986,32386",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fbonev%2FCN_mapq30.hic": "53138031154,32386",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FKieffer-Kwon_et_al_2017%2FMain_maps%2FKieffer-Kwon-2017-activated_B_cells_72_hours_WT.hic": "16492991184,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FKieffer-Kwon_et_al_2017%2FMain_maps%2FKieffer-Kwon-2017-resting_B_cells_WT.hic": "23057795398,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FKieffer-Kwon_et_al_2017%2FMain_maps%2FKieffer-Kwon-2017-activated_B_cells_24_hours_MYC_KO.hic": "21245554876,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FKieffer-Kwon_et_al_2017%2FMain_maps%2FKieffer-Kwon-2017-resting_B_cells_TSA.hic": "13933346059,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FKieffer-Kwon_et_al_2017%2FMain_maps%2FKieffer-Kwon-2017-activated_B_cells_24_hours_oligomycin.hic": "21902506832,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FKieffer-Kwon_et_al_2017%2FMain_maps%2FKieffer-Kwon-2017-mES_WT.hic": "9451969795,32102",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FVian_et_al_2018%2FMain_maps%2FVian-2018-activated_B_cells_24_hours_WT.hic": "3600188564,16438",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FVian_et_al_2018%2FMain_maps%2FVian-2018-activated_B_cells_30_hours_WT.hic": "1309503359,16438",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FVian_et_al_2018%2FMain_maps%2FVian-2018-activated_B_cells_30_hours_HU_treated.hic": "1477252396,16438",
    "hicfiles.s3.amazonaws.com%2Fhiseq%2FVian_et_al_2018%2FMain_maps%2FVian-2018-activated_B_cells_24_hours_flavopiridol.hic": "15711773490,32102",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FPGP1F_mega_090118%2FPGP1F_mega_090118_30.hic": "24786676166,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7145%2FHIC7145_30.hic": "4554644616,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7146%2FHIC7146_30.hic": "4174848944,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7147%2FHIC7147_30.hic": "5062229182,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7862%2FHIC7862_30.hic": "4520990620,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7863%2FHIC7863_30.hic": "4330354773,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7864%2FHIC7864_30.hic": "5880640268,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7865%2FHIC7865_30.hic": "4431632469,36479",
    "hicfiles.s3.amazonaws.com%2Fexternal%2Fpgp%2FHIC7866%2FHIC7866_30.hic": "4686000222,36479",
    "encode-public.s3.amazonaws.com%2F2018%2F10%2F19%2Fb122542b-306c-4631-bdc3-2e155e89af55%2FENCFF718AWL.hic": "39544267907,58932",
    "encode-public.s3.amazonaws.com%2F2019%2F02%2F15%2F8e787cd8-e388-4bc2-a236-7a5e6f39e0c0%2FENCFF999YXX.hic": "9255069996,56883",
    "encode-public.s3.amazonaws.com%2F2019%2F02%2F08%2Ffc1d9d5d-8fa0-4e29-9080-3da674d9490d%2FENCFF543USQ.hic": "5005263062,36479",
    "s3.us-east-1.wasabisys.com%2Fhicfiles%2Finternal%2FLCL_mega_42B_500bp_30.hic": "106482584851,22829",
    "www.encodeproject.org%2Ffiles%2FENCFF925QIF%2F%40%40download%2FENCFF925QIF.hic": "51712684396,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF259YUS%2F%40%40download%2FENCFF259YUS.hic": "24640030444,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF783KQI%2F%40%40download%2FENCFF783KQI.hic": "27209249856,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF318JAP%2F%40%40download%2FENCFF318JAP.hic": "28636979242,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF512PQA%2F%40%40download%2FENCFF512PQA.hic": "28124104361,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF591MHA%2F%40%40download%2FENCFF591MHA.hic": "37373387202,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF197OWW%2F%40%40download%2FENCFF197OWW.hic": "41826180893,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF317OIA%2F%40%40download%2FENCFF317OIA.hic": "34741636038,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF522YLZ%2F%40%40download%2FENCFF522YLZ.hic": "24064692329,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF420JTA%2F%40%40download%2FENCFF420JTA.hic": "21202405177,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF871ZDJ%2F%40%40download%2FENCFF871ZDJ.hic": "10124815680,18679",
    "www.encodeproject.org%2Ffiles%2FENCFF349RZY%2F%40%40download%2FENCFF349RZY.hic": "4446702705,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF738YON%2F%40%40download%2FENCFF738YON.hic": "3338049584,35947",
    "www.encodeproject.org%2Ffiles%2FENCFF406KJN%2F%40%40download%2FENCFF406KJN.hic": "4132875306,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF081NPN%2F%40%40download%2FENCFF081NPN.hic": "34495587443,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF946RZW%2F%40%40download%2FENCFF946RZW.hic": "34571955193,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF602CHT%2F%40%40download%2FENCFF602CHT.hic": "30674798032,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF150DKS%2F%40%40download%2FENCFF150DKS.hic": "12618769864,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF614IPZ%2F%40%40download%2FENCFF614IPZ.hic": "42450060331,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF571ZQR%2F%40%40download%2FENCFF571ZQR.hic": "15230068871,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF705MKK%2F%40%40download%2FENCFF705MKK.hic": "38686554702,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF658KTB%2F%40%40download%2FENCFF658KTB.hic": "13195884405,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF435JYN%2F%40%40download%2FENCFF435JYN.hic": "17192139674,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF016JKX%2F%40%40download%2FENCFF016JKX.hic": "37266736642,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF294GFP%2F%40%40download%2FENCFF294GFP.hic": "36389843399,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF700CYI%2F%40%40download%2FENCFF700CYI.hic": "60482012152,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF962EDB%2F%40%40download%2FENCFF962EDB.hic": "39394328182,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF467OGP%2F%40%40download%2FENCFF467OGP.hic": "35572788855,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF556RLR%2F%40%40download%2FENCFF556RLR.hic": "34826921187,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF309UNV%2F%40%40download%2FENCFF309UNV.hic": "48510618174,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF653HCO%2F%40%40download%2FENCFF653HCO.hic": "41079007620,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF120DSV%2F%40%40download%2FENCFF120DSV.hic": "12790195576,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF284OEA%2F%40%40download%2FENCFF284OEA.hic": "46007570339,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF035BLF%2F%40%40download%2FENCFF035BLF.hic": "63868871985,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF094KKX%2F%40%40download%2FENCFF094KKX.hic": "42151419971,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF965BPU%2F%40%40download%2FENCFF965BPU.hic": "42717376107,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF842DUO%2F%40%40download%2FENCFF842DUO.hic": "39553892515,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF417GBZ%2F%40%40download%2FENCFF417GBZ.hic": "45544785452,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF499BVX%2F%40%40download%2FENCFF499BVX.hic": "40900674170,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF136XCV%2F%40%40download%2FENCFF136XCV.hic": "47700392974,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF224HKR%2F%40%40download%2FENCFF224HKR.hic": "40426005932,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF711XSR%2F%40%40download%2FENCFF711XSR.hic": "41434581949,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF660JWA%2F%40%40download%2FENCFF660JWA.hic": "54201632697,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF081PMO%2F%40%40download%2FENCFF081PMO.hic": "53428285130,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF799QGA%2F%40%40download%2FENCFF799QGA.hic": "1228682189,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF473CAA%2F%40%40download%2FENCFF473CAA.hic": "1077514950,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF663FRL%2F%40%40download%2FENCFF663FRL.hic": "39843938245,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF341WOY%2F%40%40download%2FENCFF341WOY.hic": "34792598619,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF515ZBF%2F%40%40download%2FENCFF515ZBF.hic": "41779230923,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF545GBW%2F%40%40download%2FENCFF545GBW.hic": "38351980358,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF252ANV%2F%40%40download%2FENCFF252ANV.hic": "37668839771,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF912NDK%2F%40%40download%2FENCFF912NDK.hic": "21196851956,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF683PLM%2F%40%40download%2FENCFF683PLM.hic": "21849338763,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF355NFJ%2F%40%40download%2FENCFF355NFJ.hic": "47880627268,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF507WEW%2F%40%40download%2FENCFF507WEW.hic": "35777782924,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF241MSL%2F%40%40download%2FENCFF241MSL.hic": "22507408288,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF135MUT%2F%40%40download%2FENCFF135MUT.hic": "24168734040,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF459FRB%2F%40%40download%2FENCFF459FRB.hic": "37166770103,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF705YZH%2F%40%40download%2FENCFF705YZH.hic": "44679182442,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF395INO%2F%40%40download%2FENCFF395INO.hic": "56727346538,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF736ITL%2F%40%40download%2FENCFF736ITL.hic": "49036286185,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF546TZN%2F%40%40download%2FENCFF546TZN.hic": "33195126348,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF706SFK%2F%40%40download%2FENCFF706SFK.hic": "3271831702,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF922ERE%2F%40%40download%2FENCFF922ERE.hic": "12476647122,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF876OWE%2F%40%40download%2FENCFF876OWE.hic": "1538692704,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF227XJZ%2F%40%40download%2FENCFF227XJZ.hic": "1925617685,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF053BXY%2F%40%40download%2FENCFF053BXY.hic": "1032237652,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF014VMM%2F%40%40download%2FENCFF014VMM.hic": "1564021703,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF563XES%2F%40%40download%2FENCFF563XES.hic": "1489180508,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF482LGO%2F%40%40download%2FENCFF482LGO.hic": "1208778887,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF216QQM%2F%40%40download%2FENCFF216QQM.hic": "6293163571,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF256UOW%2F%40%40download%2FENCFF256UOW.hic": "6293163571,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF791UZC%2F%40%40download%2FENCFF791UZC.hic": "37029615181,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF911AHQ%2F%40%40download%2FENCFF911AHQ.hic": "34246649165,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF491AOR%2F%40%40download%2FENCFF491AOR.hic": "558663570,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF307PDL%2F%40%40download%2FENCFF307PDL.hic": "525321519,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF773ITV%2F%40%40download%2FENCFF773ITV.hic": "295464771,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF198SSL%2F%40%40download%2FENCFF198SSL.hic": "500142476,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF251UEF%2F%40%40download%2FENCFF251UEF.hic": "567443575,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF942LTN%2F%40%40download%2FENCFF942LTN.hic": "4430960222,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF543USQ%2F%40%40download%2FENCFF543USQ.hic": "5005263062,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF434XQU%2F%40%40download%2FENCFF434XQU.hic": "25506511674,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF318GOM%2F%40%40download%2FENCFF318GOM.hic": "44750824314,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF004TKY%2F%40%40download%2FENCFF004TKY.hic": "20705661833,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF493YNC%2F%40%40download%2FENCFF493YNC.hic": "53407154984,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF334XXU%2F%40%40download%2FENCFF334XXU.hic": "14341194962,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF569RJM%2F%40%40download%2FENCFF569RJM.hic": "1922066634,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF076LWH%2F%40%40download%2FENCFF076LWH.hic": "39406255845,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF977XWK%2F%40%40download%2FENCFF977XWK.hic": "20563512962,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF127TPS%2F%40%40download%2FENCFF127TPS.hic": "21032070512,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF480KLP%2F%40%40download%2FENCFF480KLP.hic": "33115569647,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF281ILS%2F%40%40download%2FENCFF281ILS.hic": "28236353584,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF129LMU%2F%40%40download%2FENCFF129LMU.hic": "35247096013,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF759YCW%2F%40%40download%2FENCFF759YCW.hic": "16058537743,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF045YOM%2F%40%40download%2FENCFF045YOM.hic": "19775445828,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF013TGD%2F%40%40download%2FENCFF013TGD.hic": "4208420532,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF929RPW%2F%40%40download%2FENCFF929RPW.hic": "1974260276,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF996XEO%2F%40%40download%2FENCFF996XEO.hic": "2072918865,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF097SKJ%2F%40%40download%2FENCFF097SKJ.hic": "1983524001,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF464KRA%2F%40%40download%2FENCFF464KRA.hic": "1957300131,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF406HHC%2F%40%40download%2FENCFF406HHC.hic": "3854527235,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF080DPJ%2F%40%40download%2FENCFF080DPJ.hic": "19953768024,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF621AIY%2F%40%40download%2FENCFF621AIY.hic": "26781700010,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF366ERB%2F%40%40download%2FENCFF366ERB.hic": "1049184239,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF997RGL%2F%40%40download%2FENCFF997RGL.hic": "2521167549,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF029MPB%2F%40%40download%2FENCFF029MPB.hic": "1854711185,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF043EEE%2F%40%40download%2FENCFF043EEE.hic": "2924133032,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF920CJR%2F%40%40download%2FENCFF920CJR.hic": "3011577886,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF928NJV%2F%40%40download%2FENCFF928NJV.hic": "2778936244,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF894GLR%2F%40%40download%2FENCFF894GLR.hic": "2831733099,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF303PCK%2F%40%40download%2FENCFF303PCK.hic": "688704435,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF999YXX%2F%40%40download%2FENCFF999YXX.hic": "9255069996,56883",
    "www.encodeproject.org%2Ffiles%2FENCFF685BLG%2F%40%40download%2FENCFF685BLG.hic": "16797022992,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF188SSH%2F%40%40download%2FENCFF188SSH.hic": "16797023050,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF465ESX%2F%40%40download%2FENCFF465ESX.hic": "13489465953,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF493SFI%2F%40%40download%2FENCFF493SFI.hic": "30033046818,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF235LCO%2F%40%40download%2FENCFF235LCO.hic": "38572174589,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF625VNK%2F%40%40download%2FENCFF625VNK.hic": "45202258438,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF304HMS%2F%40%40download%2FENCFF304HMS.hic": "36994445315,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF605CEN%2F%40%40download%2FENCFF605CEN.hic": "40358346301,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF086ORS%2F%40%40download%2FENCFF086ORS.hic": "22650781304,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF237UKR%2F%40%40download%2FENCFF237UKR.hic": "41516707246,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF004YZQ%2F%40%40download%2FENCFF004YZQ.hic": "71900665326,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF104THR%2F%40%40download%2FENCFF104THR.hic": "15172410632,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF807IRK%2F%40%40download%2FENCFF807IRK.hic": "17346637451,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF193CQL%2F%40%40download%2FENCFF193CQL.hic": "55726903245,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF700DEX%2F%40%40download%2FENCFF700DEX.hic": "20231399798,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF234MDO%2F%40%40download%2FENCFF234MDO.hic": "24635312640,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF181ROW%2F%40%40download%2FENCFF181ROW.hic": "26883606497,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF896OFN%2F%40%40download%2FENCFF896OFN.hic": "36952196833,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF573OPJ%2F%40%40download%2FENCFF573OPJ.hic": "36685546919,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF028RXH%2F%40%40download%2FENCFF028RXH.hic": "31053919206,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF156GGD%2F%40%40download%2FENCFF156GGD.hic": "35284134289,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF496GEU%2F%40%40download%2FENCFF496GEU.hic": "44659660933,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF945TUH%2F%40%40download%2FENCFF945TUH.hic": "3898570191,35639",
    "www.encodeproject.org%2Ffiles%2FENCFF698KFV%2F%40%40download%2FENCFF698KFV.hic": "4427896684,35751",
    "www.encodeproject.org%2Ffiles%2FENCFF239BHZ%2F%40%40download%2FENCFF239BHZ.hic": "1718552021,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF277LAN%2F%40%40download%2FENCFF277LAN.hic": "4542391336,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF397CMD%2F%40%40download%2FENCFF397CMD.hic": "2832016430,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF778OYA%2F%40%40download%2FENCFF778OYA.hic": "14067187484,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF775VSU%2F%40%40download%2FENCFF775VSU.hic": "14067187484,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF812THZ%2F%40%40download%2FENCFF812THZ.hic": "9667720429,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF777KBU%2F%40%40download%2FENCFF777KBU.hic": "5058284320,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF688KOY%2F%40%40download%2FENCFF688KOY.hic": "4198695833,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF223UBX%2F%40%40download%2FENCFF223UBX.hic": "1206064037,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF273XBU%2F%40%40download%2FENCFF273XBU.hic": "44085607579,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF952JZV%2F%40%40download%2FENCFF952JZV.hic": "44728845246,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF643NDM%2F%40%40download%2FENCFF643NDM.hic": "35877886780,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF586MQY%2F%40%40download%2FENCFF586MQY.hic": "71158037547,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF251VFA%2F%40%40download%2FENCFF251VFA.hic": "40089479399,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF033WGK%2F%40%40download%2FENCFF033WGK.hic": "30802023728,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF649OHR%2F%40%40download%2FENCFF649OHR.hic": "28203184265,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF782WVZ%2F%40%40download%2FENCFF782WVZ.hic": "37248221674,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF579CAR%2F%40%40download%2FENCFF579CAR.hic": "39662025395,54779",
    "www.encodeproject.org%2Ffiles%2FENCFF514XWQ%2F%40%40download%2FENCFF514XWQ.hic": "1319613701,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF666USK%2F%40%40download%2FENCFF666USK.hic": "15051670107,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF047SDP%2F%40%40download%2FENCFF047SDP.hic": "1357076705,16438",
    "www.encodeproject.org%2Ffiles%2FENCFF584LEP%2F%40%40download%2FENCFF584LEP.hic": "9314176858,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF671SOE%2F%40%40download%2FENCFF671SOE.hic": "3273732256,16438",
    "www.encodeproject.org%2Ffiles%2FENCFF702LAP%2F%40%40download%2FENCFF702LAP.hic": "1800427500,31682",
    "www.encodeproject.org%2Ffiles%2FENCFF339PON%2F%40%40download%2FENCFF339PON.hic": "5394351199,31514",
    "www.encodeproject.org%2Ffiles%2FENCFF741QPR%2F%40%40download%2FENCFF741QPR.hic": "4271510019,30139",
    "www.encodeproject.org%2Ffiles%2FENCFF103ANX%2F%40%40download%2FENCFF103ANX.hic": "4439745804,30083",
    "www.encodeproject.org%2Ffiles%2FENCFF395SBC%2F%40%40download%2FENCFF395SBC.hic": "1596732351,31850",
    "www.encodeproject.org%2Ffiles%2FENCFF289WNN%2F%40%40download%2FENCFF289WNN.hic": "8632980535,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF381EAU%2F%40%40download%2FENCFF381EAU.hic": "1702476311,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF976FEY%2F%40%40download%2FENCFF976FEY.hic": "1448279040,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF485LVS%2F%40%40download%2FENCFF485LVS.hic": "1593610856,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF143VJV%2F%40%40download%2FENCFF143VJV.hic": "1422312076,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF588NHX%2F%40%40download%2FENCFF588NHX.hic": "3943637009,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF768UBD%2F%40%40download%2FENCFF768UBD.hic": "24786676166,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF965PEE%2F%40%40download%2FENCFF965PEE.hic": "29387092778,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF702IFC%2F%40%40download%2FENCFF702IFC.hic": "29387092778,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF288DNV%2F%40%40download%2FENCFF288DNV.hic": "10313429504,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF698HWZ%2F%40%40download%2FENCFF698HWZ.hic": "10962849269,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF355OWW%2F%40%40download%2FENCFF355OWW.hic": "3219571601,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF177TYX%2F%40%40download%2FENCFF177TYX.hic": "10325679706,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF542BHD%2F%40%40download%2FENCFF542BHD.hic": "11085397879,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF684IFW%2F%40%40download%2FENCFF684IFW.hic": "11085397879,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF592UHE%2F%40%40download%2FENCFF592UHE.hic": "21363948089,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF779XIS%2F%40%40download%2FENCFF779XIS.hic": "9517383271,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF518PSL%2F%40%40download%2FENCFF518PSL.hic": "7500581463,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF275GBB%2F%40%40download%2FENCFF275GBB.hic": "7889407162,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF634KXI%2F%40%40download%2FENCFF634KXI.hic": "6430702966,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF337QPM%2F%40%40download%2FENCFF337QPM.hic": "8529733887,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF162KMP%2F%40%40download%2FENCFF162KMP.hic": "5615896908,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF992VTE%2F%40%40download%2FENCFF992VTE.hic": "7328975027,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF464WXY%2F%40%40download%2FENCFF464WXY.hic": "5715339664,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF752EGH%2F%40%40download%2FENCFF752EGH.hic": "1733758743,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF474UJM%2F%40%40download%2FENCFF474UJM.hic": "222309548,18679",
    "www.encodeproject.org%2Ffiles%2FENCFF531UPZ%2F%40%40download%2FENCFF531UPZ.hic": "1827756846,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF022VHA%2F%40%40download%2FENCFF022VHA.hic": "244983451,18679",
    "www.encodeproject.org%2Ffiles%2FENCFF732NGR%2F%40%40download%2FENCFF732NGR.hic": "1679484567,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF513VSF%2F%40%40download%2FENCFF513VSF.hic": "28397932940,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF750AOC%2F%40%40download%2FENCFF750AOC.hic": "36471430712,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF219YOB%2F%40%40download%2FENCFF219YOB.hic": "2859767211,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF604YDD%2F%40%40download%2FENCFF604YDD.hic": "3549694085,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF746AMV%2F%40%40download%2FENCFF746AMV.hic": "2954867135,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF845ZEB%2F%40%40download%2FENCFF845ZEB.hic": "2763039282,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF570LWS%2F%40%40download%2FENCFF570LWS.hic": "2861588289,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF883YVR%2F%40%40download%2FENCFF883YVR.hic": "2433679887,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF056VLK%2F%40%40download%2FENCFF056VLK.hic": "2502986910,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF532DUQ%2F%40%40download%2FENCFF532DUQ.hic": "3172615899,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF723PYJ%2F%40%40download%2FENCFF723PYJ.hic": "10292839095,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF991SGJ%2F%40%40download%2FENCFF991SGJ.hic": "13072407683,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF885UZI%2F%40%40download%2FENCFF885UZI.hic": "1201881201,16438",
    "www.encodeproject.org%2Ffiles%2FENCFF287KXA%2F%40%40download%2FENCFF287KXA.hic": "14705801988,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF335HYI%2F%40%40download%2FENCFF335HYI.hic": "20366756545,32102",
    "www.encodeproject.org%2Ffiles%2FENCFF121YPY%2F%40%40download%2FENCFF121YPY.hic": "2760104016,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF675SJE%2F%40%40download%2FENCFF675SJE.hic": "3751779995,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF876LAW%2F%40%40download%2FENCFF876LAW.hic": "4032376256,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF740KVX%2F%40%40download%2FENCFF740KVX.hic": "3202490322,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF977OQV%2F%40%40download%2FENCFF977OQV.hic": "2919735477,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF525EFN%2F%40%40download%2FENCFF525EFN.hic": "2394748073,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF690QRC%2F%40%40download%2FENCFF690QRC.hic": "3183023394,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF452FWS%2F%40%40download%2FENCFF452FWS.hic": "2951805127,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF089KBG%2F%40%40download%2FENCFF089KBG.hic": "2531936700,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF939ARM%2F%40%40download%2FENCFF939ARM.hic": "3066385212,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF401ZAN%2F%40%40download%2FENCFF401ZAN.hic": "3008677144,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF378RZT%2F%40%40download%2FENCFF378RZT.hic": "2830232168,26979",
    "www.encodeproject.org%2Ffiles%2FENCFF304AVD%2F%40%40download%2FENCFF304AVD.hic": "6164439305,50358",
    "www.encodeproject.org%2Ffiles%2FENCFF453DBX%2F%40%40download%2FENCFF453DBX.hic": "6775246670,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF590VOM%2F%40%40download%2FENCFF590VOM.hic": "6443478922,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF246DOF%2F%40%40download%2FENCFF246DOF.hic": "6837914659,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF125FXX%2F%40%40download%2FENCFF125FXX.hic": "9736232550,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF241RAY%2F%40%40download%2FENCFF241RAY.hic": "7686758397,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF415XWQ%2F%40%40download%2FENCFF415XWQ.hic": "6973092068,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF374EBH%2F%40%40download%2FENCFF374EBH.hic": "8557459058,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF263YWR%2F%40%40download%2FENCFF263YWR.hic": "331586820,18679",
    "www.encodeproject.org%2Ffiles%2FENCFF280CGS%2F%40%40download%2FENCFF280CGS.hic": "1743569614,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF553ZNS%2F%40%40download%2FENCFF553ZNS.hic": "1717253636,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF451VOI%2F%40%40download%2FENCFF451VOI.hic": "1626927468,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF420MQO%2F%40%40download%2FENCFF420MQO.hic": "31055961677,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF301BWY%2F%40%40download%2FENCFF301BWY.hic": "38902429246,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF606XNW%2F%40%40download%2FENCFF606XNW.hic": "1009699327,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF439ZOU%2F%40%40download%2FENCFF439ZOU.hic": "12381714313,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF499SYK%2F%40%40download%2FENCFF499SYK.hic": "13182654663,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF532LFI%2F%40%40download%2FENCFF532LFI.hic": "8286030286,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF020DPP%2F%40%40download%2FENCFF020DPP.hic": "23521952420,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF306VTV%2F%40%40download%2FENCFF306VTV.hic": "23521952480,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF399IHE%2F%40%40download%2FENCFF399IHE.hic": "10216571232,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF944AII%2F%40%40download%2FENCFF944AII.hic": "12567308552,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF230HVV%2F%40%40download%2FENCFF230HVV.hic": "8629849711,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF174LAF%2F%40%40download%2FENCFF174LAF.hic": "9446506633,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF898HRO%2F%40%40download%2FENCFF898HRO.hic": "9446506633,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF429MOR%2F%40%40download%2FENCFF429MOR.hic": "1455757398,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF061NXV%2F%40%40download%2FENCFF061NXV.hic": "1710915891,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF434PYS%2F%40%40download%2FENCFF434PYS.hic": "1832274903,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF704HHX%2F%40%40download%2FENCFF704HHX.hic": "1768142834,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF349DPM%2F%40%40download%2FENCFF349DPM.hic": "4316041260,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF718AWL%2F%40%40download%2FENCFF718AWL.hic": "39544267907,58932",
    "www.encodeproject.org%2Ffiles%2FENCFF065LSP%2F%40%40download%2FENCFF065LSP.hic": "24929805283,55874",
    "www.encodeproject.org%2Ffiles%2FENCFF632MFV%2F%40%40download%2FENCFF632MFV.hic": "22333105467,55798",
    "www.encodeproject.org%2Ffiles%2FENCFF179HVU%2F%40%40download%2FENCFF179HVU.hic": "2659219603,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF791SPZ%2F%40%40download%2FENCFF791SPZ.hic": "2537491657,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF211ZWF%2F%40%40download%2FENCFF211ZWF.hic": "3512141227,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF614BNU%2F%40%40download%2FENCFF614BNU.hic": "5252155462,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF891DLM%2F%40%40download%2FENCFF891DLM.hic": "2549095612,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF056EXT%2F%40%40download%2FENCFF056EXT.hic": "2219014704,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF776DSS%2F%40%40download%2FENCFF776DSS.hic": "3920308353,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF814LQF%2F%40%40download%2FENCFF814LQF.hic": "1349480197,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF513ZNA%2F%40%40download%2FENCFF513ZNA.hic": "2638868195,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF692IOL%2F%40%40download%2FENCFF692IOL.hic": "3106768498,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF294WZY%2F%40%40download%2FENCFF294WZY.hic": "2211089761,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF339UWS%2F%40%40download%2FENCFF339UWS.hic": "2142759055,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF835FSK%2F%40%40download%2FENCFF835FSK.hic": "3919821739,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF328BMB%2F%40%40download%2FENCFF328BMB.hic": "2550686152,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF897RTY%2F%40%40download%2FENCFF897RTY.hic": "1323294397,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF306MWH%2F%40%40download%2FENCFF306MWH.hic": "1665386199,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF039YUJ%2F%40%40download%2FENCFF039YUJ.hic": "2335679939,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF468QIN%2F%40%40download%2FENCFF468QIN.hic": "3352516594,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF364WXU%2F%40%40download%2FENCFF364WXU.hic": "3959043175,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF373MSH%2F%40%40download%2FENCFF373MSH.hic": "4429550624,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF959EGQ%2F%40%40download%2FENCFF959EGQ.hic": "2475218228,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF624XMK%2F%40%40download%2FENCFF624XMK.hic": "3527855681,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF445OKS%2F%40%40download%2FENCFF445OKS.hic": "3369125589,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF239QDV%2F%40%40download%2FENCFF239QDV.hic": "1960069888,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF645AVM%2F%40%40download%2FENCFF645AVM.hic": "4969006589,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF494IPI%2F%40%40download%2FENCFF494IPI.hic": "1565483792,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF753IXE%2F%40%40download%2FENCFF753IXE.hic": "2892400648,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF731GXX%2F%40%40download%2FENCFF731GXX.hic": "5131381303,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF369CAQ%2F%40%40download%2FENCFF369CAQ.hic": "4236629448,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF053VBX%2F%40%40download%2FENCFF053VBX.hic": "54305946375,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF555ISR%2F%40%40download%2FENCFF555ISR.hic": "54305946434,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF964RSP%2F%40%40download%2FENCFF964RSP.hic": "12065485229,36479",
    "www.encodeproject.org%2Ffiles%2FENCFF234MZQ%2F%40%40download%2FENCFF234MZQ.hic": "17507142760,47429",
    "www.encodeproject.org%2Ffiles%2FENCFF994CEG%2F%40%40download%2FENCFF994CEG.hic": "17507142760,47429",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb068e787-bbdd-49e2-9f6d-7a0e9f065d6e%2F4DNFIHSUIRFY.hic": "2701182442,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3768b89a-e124-40eb-8acd-94a4fdd35807%2F4DNFIXTAS6EE.hic": "3283740601,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9c513602-5abb-4088-8415-c827c381ab0d%2F4DNFIC3JD6O2.hic": "4896075767,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fde954dad-1849-4c23-beb4-bf893b7d76c9%2F4DNFIGY85S4W.hic": "3364151996,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe1befa6e-8067-4296-8db2-67d2a2458985%2F4DNFI2LTNXME.hic": "3699291418,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbb3307fd-7162-477a-87c5-52f12d03befc%2F4DNFID162B9J.hic": "3576428773,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8597cdae-8319-42d9-9175-3f77aaee34e1%2F4DNFIQWS3632.hic": "4022561190,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F327f091d-6a63-47c4-9752-2dff303a13d9%2F4DNFI6GFHB6G.hic": "4239331168,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd70dc3b8-48a7-42c2-bd23-80bc44fc2266%2F4DNFIS5F6EBL.hic": "4404671861,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F77df7bf8-6a3d-407a-8968-4ca1e78d2269%2F4DNFIF2D3TL9.hic": "4793989394,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc8f7cc35-d2ef-4717-9009-c02154050fbf%2F4DNFI6EIFUZI.hic": "3370273763,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F98449d79-30d1-4e32-801d-07435819a42f%2F4DNFIRKGMZRQ.hic": "4669813207,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd4fe1844-2493-4722-8b98-34a93a4bb7d8%2F4DNFIQWD8ZYD.hic": "5379889057,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1ee40499-1de4-47ff-b540-d9a65943c6b8%2F4DNFIX7H3CE2.hic": "4857679239,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fac016170-23a7-4111-8773-353d6c150f34%2F4DNFIO21YDCV.hic": "4695989644,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1faea4e7-9a32-466e-a826-0bfdab6cd915%2F4DNFIUE5RAS6.hic": "4788044799,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcbd396ed-11bb-404f-aed9-ef10abc258e6%2F4DNFIFI6NIKJ.hic": "2069414927,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faf3f78db-ae92-4215-bcae-76d30c617e5d%2F4DNFIL5K3MA1.hic": "2585598247,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F66fc2893-224e-48b4-bdb0-25e022dedd03%2F4DNFIPV5KFMK.hic": "2060965679,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2b39781d-aadc-434f-abe3-562d61308fdd%2F4DNFIW8TIFI5.hic": "1694424195,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb6d28f7b-27d3-486f-b5ac-32567416560d%2F4DNFIA6NSSBJ.hic": "2219532611,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F08785616-722e-46bb-ade2-2fdd21324e11%2F4DNFIBKZK63V.hic": "2429136232,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F904b13d3-1203-42a4-812c-963e80c159b1%2F4DNFI3CFMRID.hic": "1233390166,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb0ed4afe-5ecf-451d-bb1f-444697e3f558%2F4DNFIYIAUEPW.hic": "1753567534,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4bb9a9b3-12a8-4d2f-8071-4722294c88ea%2F4DNFIC3HAU11.hic": "2271375254,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5abf9c36-c1d1-44dc-8197-74d860d896c0%2F4DNFIM2BMJ33.hic": "1770471113,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa68475db-7816-4cec-b2da-d4ce4a0c7a0e%2F4DNFIBY3UHJB.hic": "2095528423,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F07c40943-0d0b-4b3e-94a0-8583b0e00b47%2F4DNFIWZEN3FF.hic": "1558559508,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd8a39553-2718-4866-9b2a-45d8e9c5ac6f%2F4DNFIE5TEYK7.hic": "1323063102,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F294b61ef-5565-4f04-ade9-f921a6686cce%2F4DNFI7H4II2V.hic": "1436947982,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F87a70ad7-7d34-4302-9c03-eb824bd4e633%2F4DNFIAS8LV1C.hic": "1263105698,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9db804c6-5a91-4cf5-8ae9-e054223afb77%2F4DNFIBXWIH8J.hic": "1587059951,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd1e55906-ec8f-444b-8ce1-2de22a431a76%2F4DNFIO2HBDAI.hic": "1666436393,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7a4b9b37-8e38-470c-8190-fd4d513c0bbb%2F4DNFIXWCLD4M.hic": "1513511880,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6c7615b2-5310-4bbf-a605-98761d3a3079%2F4DNFINASC4NQ.hic": "1420358914,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F20454014-49cd-4e2d-93da-f40ad52776d5%2F4DNFIZF1KI43.hic": "1377041630,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5df04e86-ee2a-45f2-b878-bf1648e2cca5%2F4DNFIQBHR9XC.hic": "1179502734,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9d1cc910-bb3f-4106-802a-73184c58d5ba%2F4DNFIQVMVRP6.hic": "1811976991,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe431986b-0732-460f-92ff-0abd1694d2d0%2F4DNFIJ7OAIFQ.hic": "1685503856,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F56d0c575-4962-4ea3-92c3-55bd0e17c096%2F4DNFIE8E4ANZ.hic": "1598369564,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdb1502fb-a989-4ef9-b800-7eaa7be172ae%2F4DNFIOUSETUP.hic": "1493294709,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Facc2f1c3-8c96-4d21-814a-3a8ba81e7741%2F4DNFIVJ3IIQL.hic": "1472376655,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F037fb4c3-8641-4953-a977-cc71f2137582%2F4DNFIHN2QSMX.hic": "1179632606,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F16d2c3c0-6d29-4815-b074-b142af90b615%2F4DNFIILDWK8M.hic": "2280059989,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F31789849-e4db-45d8-9730-66a048c02d8a%2F4DNFI6XD6JAQ.hic": "2253961696,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fff04947e-e6e8-4d62-8374-ef2ee4104809%2F4DNFIALNLR78.hic": "1914687914,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F53e6c1e7-9410-4158-84e6-57c2e683b095%2F4DNFIBT4EAKN.hic": "2296378356,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0127900a-258f-4815-97d2-6fd2b043d853%2F4DNFIDJ2JZ7Q.hic": "2558945393,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F89f95693-3d28-44eb-9d45-14932f8e258c%2F4DNFI8JFOAJT.hic": "1726440253,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd803d818-b0d4-4796-8d61-a9f3943f3b10%2F4DNFIRZA6EV6.hic": "1805017504,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff9165fb1-0782-4757-a740-5b929e0252bb%2F4DNFIB7QTIMH.hic": "2541020707,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff8d916b2-b58e-4cdf-894c-ffcb3bf6ebd9%2F4DNFIJ5DQZVK.hic": "2445734043,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F14066a5b-e01a-43c2-a468-b18826174df3%2F4DNFISTWNV9C.hic": "2499319890,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd0692151-7f9a-4475-b059-f8802616dded%2F4DNFIBN9K4V9.hic": "1591570769,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F70a7ef36-a514-4e45-918f-47c4e9e30ef4%2F4DNFIR8OU6QJ.hic": "1689882108,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F180306cd-8593-413a-ae83-24f19209c5d2%2F4DNFIDSWKFF5.hic": "1596696630,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6e7b9be7-f465-4434-8e9c-68d1e44a76af%2F4DNFIV239W4L.hic": "2345867406,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F55df948c-941d-4930-854c-6a07557a0c1d%2F4DNFIREVU1Z5.hic": "2254683002,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3f590cb7-df3a-498f-81c3-d5ed4179b8ad%2F4DNFIFHU75I5.hic": "2497670883,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8f064770-6008-4f74-bfca-268d4a22d745%2F4DNFIMROE6N4.hic": "9850583704,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa93d0588-76b7-4ccf-933c-4325876b0a53%2F4DNFI5SUHCGZ.hic": "10364705187,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2f5f0d6b-0b22-4936-93c9-0944d49c037b%2F4DNFII84FBKM.hic": "8836986129,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F12e90fc5-0b76-4e42-b346-58d267f9ad6e%2F4DNFI2J1ODWB.hic": "13410858286,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff2702a45-3e8b-4980-bcd5-6bb0e291614f%2F4DNFIJWBWE41.hic": "20515676755,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa0859349-5f06-4ad3-b56f-b1166b34a9eb%2F4DNFIIMZB6Y9.hic": "15350325963,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F12685c57-d54a-46fa-9efb-acf34ed77574%2F4DNFICJECID5.hic": "1525002266,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd101a58e-89aa-44fd-96c5-6bf1e2476272%2F4DNFIR6UW2QA.hic": "1264999913,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3d8c73bf-1335-4af4-b124-e3622d48c5f6%2F4DNFIGOEKBUZ.hic": "1905947578,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcd2477cf-6ed0-4d7b-b4e9-88fb38b0df48%2F4DNFIMDNAEW4.hic": "996444147,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0f03953e-29da-4f2e-8c05-572d0edf4525%2F4DNFI5OSZ3JC.hic": "369361754,22829",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F29d95dbb-005a-4ae1-8b9c-0fec9ec0bbd0%2F4DNFI7KOUGFM.hic": "1227434049,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa441ab7b-afbe-437d-bf98-b6b916558642%2F4DNFIGKX4A32.hic": "883933242,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F941b2f94-2fff-4d3c-aceb-fee4a5ae41a9%2F4DNFI1ORHOTA.hic": "1140970677,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8720ede9-093f-4c00-912f-373283260a16%2F4DNFIWSVLZED.hic": "458091151,26979",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe9e17155-2b62-4a68-afb2-31dfa674e169%2F4DNFI6H926RO.hic": "3552863515,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F22f22778-987a-4dc0-97bc-f26eae0156d9%2F4DNFIKTYSPOF.hic": "2939475400,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F40f40192-0acb-44cb-8778-8d21afe593fb%2F4DNFIZ4S52BV.hic": "3067438113,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd8767374-7018-4e11-9226-bf5612d39ebb%2F4DNFI3APMXHF.hic": "3258874744,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8c5140ec-a65a-4186-8c59-2927233eecaa%2F4DNFIQLIKDSD.hic": "2746398416,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8cdedda6-d7d8-4231-a85b-d56fcd962366%2F4DNFIKYXMF1E.hic": "14442148774,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faa4d4497-7c6c-4dad-8040-be13ba07ea32%2F4DNFI9815EEJ.hic": "17960817603,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe9b7b95f-190b-49fe-8047-ef4a8fc59746%2F4DNFIQ2687RT.hic": "15954277087,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F59863d3a-8d7c-49c7-9a41-7f2a58ad24d1%2F4DNFIYDBBSE3.hic": "16499534862,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3e80ca41-b3e0-4f4f-a05e-8f95fe96d938%2F4DNFIMOCN1YS.hic": "863725312,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F96f97a01-0020-4f09-93e8-fc8a9fd07218%2F4DNFI6558CM8.hic": "1597967345,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fce9e9334-ee0b-4549-a845-110470ba5986%2F4DNFIQZRKS7Y.hic": "907626411,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdaac8209-1015-48da-9e31-2e252dfd8fe0%2F4DNFIX4DLXSE.hic": "1369766288,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F268b7d52-9655-474c-9467-8ba31bb2195c%2F4DNFII3JV8I1.hic": "856656441,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9681f9b5-335a-4f56-afa1-15b58bbb41e8%2F4DNFI5IAH9H1.hic": "1495185175,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3e082eab-95aa-4677-88ed-9d7adcc1effb%2F4DNFIK5HY1GP.hic": "2135337789,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F31af1ead-4520-4669-957e-424b4b197c33%2F4DNFIFA89L5B.hic": "1975593640,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcc2b78fd-52d4-4e0c-ac93-b84ee82daf7e%2F4DNFICXCFGEI.hic": "2628202920,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff54efece-280f-46ca-95e3-1f8a3cd18820%2F4DNFIK4CECUH.hic": "1901129375,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1181c0c4-afb7-4b6a-9fdc-d868fb2253fc%2F4DNFI1EYIGOC.hic": "1947135384,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1d3e2905-b9b9-4b91-bcb4-8d34746e78f6%2F4DNFI7TLEWUI.hic": "1185758726,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faba3d42f-39cd-4a85-9d3f-835aefb5c803%2F4DNFIVCJKHMN.hic": "687240974,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F27f54fcb-54fe-41a4-b25a-2f8944c89044%2F4DNFIN8F14CS.hic": "1304082029,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb75fedb5-88f4-4bc0-8e38-298df7847a7f%2F4DNFI5LCW273.hic": "12497258313,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2f22b2a0-3712-496e-92d2-63775ec0e421%2F4DNFIVSCH2CH.hic": "12594733778,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F07e47261-5863-44f5-9198-76e263081bea%2F4DNFIZ268JGC.hic": "1499059135,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F682b1ae1-3ac3-40e4-a330-c49f63946952%2F4DNFIJ1P4HBI.hic": "1190211725,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F197e7c89-ea73-41de-8cb2-8c0b1e3bb945%2F4DNFITEGKBIW.hic": "2061501971,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F191f6928-c9a8-44b2-8516-ee4d6005f24b%2F4DNFIE1P6RVH.hic": "1711447310,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F94435086-3701-4b28-a6d0-4a59b24d7615%2F4DNFICJ6I4DO.hic": "1557899940,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faa9ac173-d233-41c7-8985-ca91949664e4%2F4DNFIKUG8MEW.hic": "1339072088,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fba6d8ab6-ee18-4ba2-8da9-d631888dd50a%2F4DNFICFZGFAV.hic": "1261083478,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F87a3ab04-7094-4673-81e4-ac8c9add8eb5%2F4DNFIQXCZVVA.hic": "1084913802,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbc1ff488-82af-4579-9424-959184edb139%2F4DNFIB6PJFJ3.hic": "1114571343,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1f7026ee-987a-41e7-8a61-45510ee3f86b%2F4DNFIX97731O.hic": "1246860402,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6186787c-2e72-4925-9729-02121e2e4597%2F4DNFIYQYZOTO.hic": "1212210849,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3b98a946-0109-4efa-a13e-cc7a36772125%2F4DNFIPXU7V25.hic": "1696288217,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fea17d041-c6ed-43a1-8047-535f3d19ad44%2F4DNFIL39PR76.hic": "1719284710,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F72774afe-f166-42ac-9849-4070b0dadf6d%2F4DNFIYLJ3R3B.hic": "1106419640,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4a45e733-55f8-4a8e-be24-2ef34c976e25%2F4DNFIL51WBN6.hic": "1327651164,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F086c62b0-a26f-41ae-a5ff-88b8be2bd2aa%2F4DNFI6SFPUDA.hic": "1336964405,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4d9b79c7-af62-4070-84a9-b953903f2732%2F4DNFI2KM22QR.hic": "953067310,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3c9889d1-f49e-4639-8e15-2d99d6cd22e7%2F4DNFIVF8Q45U.hic": "984193565,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa080dd7b-b7bb-4eae-a275-572a8b45b2d0%2F4DNFI2RN3WFP.hic": "1046525527,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb81860ca-c227-4b68-a298-1907fd32300a%2F4DNFI4TJTL7A.hic": "1160373401,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F74ab47dc-7b52-421c-845e-6feb33457adf%2F4DNFIYJ4TWB8.hic": "1268416204,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc6582128-a930-4bcc-941e-5c0733bdd4d5%2F4DNFIM6RGKPV.hic": "863347067,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F11a75514-4450-4295-9eda-18e3678a575a%2F4DNFIL2VWWL5.hic": "860731581,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5eb52a8c-bf14-4225-bf34-14a0aa47c369%2F4DNFIX6ZXCA8.hic": "893462267,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F66aaa4c0-1f62-4040-8ecc-4e95da23cd18%2F4DNFIEVR81FS.hic": "879736207,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F821c6e7f-96f7-47de-b7d9-4970562b3c8e%2F4DNFIAUI6BBI.hic": "996313881,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9252c362-31ab-413d-a47a-cc97bdbf243a%2F4DNFIAFEE9G2.hic": "1103265750,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc1a0bf52-2e65-4deb-871f-ede736babd41%2F4DNFIPZBEXCP.hic": "1081257762,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F729f5ce3-d7e0-466d-b781-d18049e63bfa%2F4DNFIWPKRZGU.hic": "973779283,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F80b769ee-a717-4825-9db0-dca86f913173%2F4DNFIMD9QNDX.hic": "1158688208,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F266290c0-2767-4158-85ab-7274368905b9%2F4DNFIATA1HD5.hic": "1073361089,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc46f18a8-eb24-4d35-b112-36db72bde2f5%2F4DNFIH9U4I7I.hic": "1111124316,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fec91a79e-7de7-49f7-9b3d-d28313aeddc8%2F4DNFIZ95S6TR.hic": "1242494051,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9e9390b9-da20-43bb-a8f4-9830f354ddf9%2F4DNFI5ERM9J7.hic": "1271051397,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fee8ea82e-b9e4-41ad-bced-5f4fbdaa4fec%2F4DNFI15FPSQL.hic": "1216714120,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbed7eb86-8990-42f2-b3ee-5992d6361f7c%2F4DNFIJ3DZPGM.hic": "1035089437,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdaa8a324-3bf8-424c-8a86-b8ad325cc20d%2F4DNFINW9P6XF.hic": "1215712740,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F70631758-6bf8-47a0-92b6-132574886faa%2F4DNFITS16HW8.hic": "1105283847,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe473304b-7bc8-49b8-bba1-ecb9f06914ee%2F4DNFIS3DIH4V.hic": "1036056660,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F10f9224a-111c-402a-ac25-e221ad0af9ef%2F4DNFIY3KDHP6.hic": "1071761024,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F17519b96-2bdd-4294-98f0-1535f1aef28e%2F4DNFIO8HVKOL.hic": "953793750,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffb18f896-4730-4909-86a3-867fc8e76cd6%2F4DNFIMF6CFOM.hic": "708717889,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcfee4d87-0873-40b0-b79f-35dcc8669c1c%2F4DNFIZZ77KD2.hic": "732668723,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb8b5d909-8a1b-41bb-bcdd-d108a0bde762%2F4DNFIOLO226X.hic": "951271932,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9ce81f6e-b9cf-4ec9-8bd3-517539e4b662%2F4DNFIJMS2ODT.hic": "932509067,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F506aa952-5d41-49ff-b658-916bcf88a395%2F4DNFI49F3LJ4.hic": "1208494817,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe4dc392d-ca36-4bdf-a27d-173cae34f520%2F4DNFI65MQOIJ.hic": "854630155,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F149ef8d7-e0b4-4e4f-b7e5-7226e2603746%2F4DNFIM4KEPRD.hic": "882790622,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa436f0a8-4f9a-4524-90ac-795c5b8491cf%2F4DNFIIXBIZFC.hic": "848244425,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbc8a05dc-7449-4b01-8330-a55db37ca8e6%2F4DNFIWDOOBVE.hic": "1116239020,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faef8b881-28fe-4694-9a95-1d31940e6726%2F4DNFIDT9EB5M.hic": "888289053,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff66f3162-a53d-46f2-8800-3752935d5400%2F4DNFIX2VUNV8.hic": "1226661451,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F36c782e6-2c48-4298-aa43-bd6624da003d%2F4DNFIEQHTV1R.hic": "804665819,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff15d571e-605d-4bcc-a359-0350b57a6815%2F4DNFIFW7GA64.hic": "901568712,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc2aa2e8b-ac7f-4acb-b4b5-ac5f845840fe%2F4DNFIXGXD67I.hic": "970995551,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F71b6fd7d-b249-4d65-a597-b80b95845399%2F4DNFIA7GB1NB.hic": "956785837,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6cbb1418-34db-427f-9273-5174eac81529%2F4DNFIVOJGWNP.hic": "890415052,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc69c2ee7-b6c3-4a62-8d4f-913491c9dab8%2F4DNFIW22BNB5.hic": "1075499540,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa268f28f-c6d9-4324-8989-75d2d317af1b%2F4DNFIIFBC8WN.hic": "1045459440,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6c7361d4-60a6-440b-b5be-072cada98663%2F4DNFI9ZBEBJH.hic": "759395398,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcceaad1a-e01e-4430-8bd6-24ffa6891802%2F4DNFID4SLU53.hic": "921288444,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9eee5854-b33e-4d5a-9ba8-ceffd47e7488%2F4DNFIODI1NUJ.hic": "733159777,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5e1089b0-42e8-441c-99bb-4be99bcec28b%2F4DNFIJL26LFN.hic": "723567970,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F298a429e-ea29-49d3-bfed-fa851e1e7c4c%2F4DNFI5KTF553.hic": "2213661745,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F965d29f0-e4a3-477c-8ae4-faf4a654e69a%2F4DNFIYSPGFGV.hic": "2274989349,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd88acbc1-37b9-4266-9f97-d8fed576d0f3%2F4DNFILOR6D2D.hic": "2595459358,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7b6ddbce-0ed1-4272-b8ae-32793e4ea4e4%2F4DNFI1WMMPJQ.hic": "2262433890,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fedc0c8c4-97d8-4372-9a4f-5e0cdf09d7d0%2F4DNFI47P5978.hic": "2146314403,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0edcc389-43a4-44d7-b946-fe3496f93d56%2F4DNFI7XGW6IH.hic": "2302805723,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0a802926-e235-4c80-b521-83a1774dfb46%2F4DNFIK1EJG8I.hic": "2374943035,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3c36aedb-6a58-4aa2-9b11-bb440ec174f1%2F4DNFIDQ3THN9.hic": "2341188249,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F846123cd-8828-4e51-8b8a-27deaf293858%2F4DNFI3WPNFWT.hic": "775145629,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbd06e3ad-0e37-48a3-8367-c7d5912cab03%2F4DNFI9DCUOQ1.hic": "870487824,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc2eaf9bf-9584-4cec-8685-bd74038a6c01%2F4DNFIU8AF5ZY.hic": "1276224366,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F219497b5-3f35-473b-9d4e-e1cf21c69561%2F4DNFIHW8NTQX.hic": "28864787856,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4be13558-114a-4f2d-abca-7598e2ff4757%2F4DNFI4OUMWZ8.hic": "17061013588,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F49aed637-87a1-4d4d-ae68-e5da82aec05b%2F4DNFIEYQ5ZIR.hic": "13555272651,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa2f48779-4eff-44a6-8a9d-96435aca1ddc%2F4DNFIDZT7PR8.hic": "13425047093,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb6ec6b71-27b8-4891-b10a-ab06a5270468%2F4DNFIEJEFCAM.hic": "15394960208,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F68b9a7b5-8413-44fc-8c2d-59a67b1c4166%2F4DNFIJ8JKKWJ.hic": "17012681779,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F294d45a4-8b5e-456f-a9cb-1cbbbad3a7d2%2F4DNFINEQY95T.hic": "13966775978,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbd7f3a48-b822-41e2-bd9c-f52b3aff7b6a%2F4DNFII6AN691.hic": "12367300362,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7da44594-0dc8-4e74-8e10-a957aeaea51a%2F4DNFIDBIEXI7.hic": "2015474981,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F085a0453-3784-4eba-80b2-3556e53e2f52%2F4DNFIDKJFK2S.hic": "1983111659,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc370c78c-0a99-4c3f-a29b-2dfafc6a3e86%2F4DNFIPAF4PYT.hic": "3041434294,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F907ce45f-baa5-416d-92de-e86db4bacff1%2F4DNFIWIYYNQ5.hic": "7082321112,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff13afc6b-cf69-4c9d-881f-eed4dd3c28ee%2F4DNFIHMCILEN.hic": "1739157603,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7575d891-0e3d-4a77-896a-3e1b3a8d330b%2F4DNFIJBJ6QVH.hic": "5213499748,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff0daa830-60d5-4c7c-a2e1-a4ee70b3fee7%2F4DNFIH2A7IKF.hic": "4099503507,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff4970a25-91d8-49a7-9a4a-fe0f12cb2b0f%2F4DNFIOL8KGQ2.hic": "2256042055,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F879f1850-d175-4a86-8a97-12658482e3d1%2F4DNFIWILLD6C.hic": "5476286356,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F787f538e-d619-4c8f-985b-78753fb7e57c%2F4DNFIZBXXJAF.hic": "5946467856,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb8ee785f-1016-4989-9e64-78352621d32f%2F4DNFI8PZOJHN.hic": "8307204961,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3d8997fa-528c-4531-b25b-a412de6bd472%2F4DNFIGXAMSJY.hic": "6577588285,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F87aede55-7610-4283-af0e-c6840429a31e%2F4DNFIWNNO89O.hic": "2871061417,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F93b8e020-b337-4685-81dc-40cc8a12b5e9%2F4DNFIXU2KPNQ.hic": "3401717686,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffb5cf1bd-59d6-46fe-857d-b86d9756029d%2F4DNFIHBGD6N6.hic": "840028421,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2d1bc088-d838-49b8-85fc-fea78a7353c4%2F4DNFI7J8NI4A.hic": "675705256,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F21a27b63-fd67-401b-9aa2-2798e6588d21%2F4DNFISFM9VFK.hic": "800145922,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F656c0a9d-7058-4c4b-a3ce-701d929561c1%2F4DNFIJU5XBK7.hic": "966593603,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2efacfbd-9c81-4e62-8df7-847d1ab774d5%2F4DNFIPKY8ED1.hic": "3401629450,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa50b6a26-56b5-43f2-b525-fa219fd716f1%2F4DNFIQRTP7NM.hic": "5358852759,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff5dc7a59-b998-4490-8a76-afbf3b258a49%2F4DNFIBCIA62Q.hic": "5779049277,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F62b2b237-cb02-45f7-89d9-616cf9ffb678%2F4DNFIV56OFE3.hic": "5818844506,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8ac79b4f-cfbf-4b2a-9fc2-898ed6c3e5e1%2F4DNFILIM6FDL.hic": "19637469163,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1cff8afa-5a13-4ad4-bcad-91fc3ced759c%2F4DNFIP71EWXC.hic": "17934647326,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F000789fe-9a22-4280-a71c-7ad228fd4cac%2F4DNFIM7ZVQOQ.hic": "700134697,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8d78457f-0c2b-4358-b08c-4d4ef4d9f59d%2F4DNFIS6EYHCX.hic": "628314484,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8ca3b4a0-2478-49c9-b4a2-a28430417a32%2F4DNFIKKCMS1Q.hic": "584050634,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff5041473-18ec-439d-92b1-8ad98c9b25c0%2F4DNFIBIV8OUN.hic": "522632731,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcf474ec6-abd4-431e-a21c-252fcf5ee6b3%2F4DNFIAUMRM2S.hic": "313395531,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2bb1c73e-fb0f-41c4-8620-a938cc8d24a0%2F4DNFI9ZUXG61.hic": "332217525,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F26dfa41e-7e4f-457a-a3ac-72daf8059f3c%2F4DNFIDBFENL7.hic": "359604894,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8ed2e68b-189e-4988-bb6d-94e63240a231%2F4DNFI7XAZNNQ.hic": "5312422266,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6f2b1ce0-5205-4680-981e-d91b694dc3e4%2F4DNFISNGDB53.hic": "2008546759,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F73c8147f-bc7d-4d33-8a10-a9e7b413ecc6%2F4DNFI1CIGHYC.hic": "2221847625,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F526ae3e3-32cc-4670-970f-1faa9cfafd4d%2F4DNFIQD2DP2F.hic": "5295591503,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6e803530-47f8-4c56-bb19-fbdcd578d2f0%2F4DNFINHT8P7C.hic": "3159499486,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffe705f38-903f-47b7-9b38-f80ebd18a479%2F4DNFIGF8EM7M.hic": "3490552542,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faa197328-d6da-4299-a0fd-409ac9892668%2F4DNFIUPG2ZBJ.hic": "5382003751,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffe5d52c4-2be7-4812-bca0-cd4bb3025e6b%2F4DNFIH3OTR14.hic": "3224693110,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F21935f61-daa8-4df2-aa69-fcd27953b405%2F4DNFIUATRW3Z.hic": "3835865655,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F72147527-e3e8-4967-bc45-8251249d76c9%2F4DNFI6V7ZQAE.hic": "4382708138,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F589f0919-f54d-49ff-a482-e2f453ef7f34%2F4DNFICKMT1CY.hic": "4479068191,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F861946ca-bad8-4a87-96e1-b2eb20b9ef10%2F4DNFIF9BDCNI.hic": "3698151402,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff76346ac-97ec-4893-8984-2b75ce1cdc51%2F4DNFIG5O1OQS.hic": "5704288175,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe8b43826-f049-4c3f-b83a-e75fa7aacc85%2F4DNFIHS2SVBQ.hic": "990025510,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F46316260-f555-4dd4-9e40-8ae06ca52822%2F4DNFIU95B69H.hic": "1275878544,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff62ef491-a3ae-4f66-ab33-c5673c9e0b65%2F4DNFIYRROFCR.hic": "1522530503,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1cd617e1-fad4-4fdc-8b4e-cb277650123c%2F4DNFIF7JN3IU.hic": "1463443128,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdb20fe2a-0078-4d4a-8a52-d76043d06f40%2F4DNFIB2RYULQ.hic": "1269529700,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3d386fcf-1d22-47de-a0d6-0c3a641ca174%2F4DNFIMVDK3SI.hic": "1185489599,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F00e470bd-c533-41b1-923a-4b471824da12%2F4DNFIRLOTXSC.hic": "978217499,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb8774fb3-d0c5-43cc-a6c9-eb0ca39841fb%2F4DNFIZAPX6J8.hic": "1100356082,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fab3b826e-614c-448b-860f-a3ebef68a82f%2F4DNFIQD5VCSD.hic": "1322319877,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F66c78d2b-993a-4937-95ed-9e2d302ceb3e%2F4DNFIIF6VAGN.hic": "868885799,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5d106fe1-2d80-466e-87aa-46b38ba142c5%2F4DNFI86IEZXH.hic": "751443267,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc99490b6-faf9-413f-9c38-3f53018089d6%2F4DNFIP881SR2.hic": "754427927,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F39c7e387-e710-4b1d-b757-357dec03f6ad%2F4DNFI42NNCW8.hic": "1166392978,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4742a211-43b1-4325-af6f-82490aeb766b%2F4DNFIEV4PKP6.hic": "1102227476,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Feaa73842-1c86-4ad9-8a9e-c189c0b4c01d%2F4DNFIF1DUQHE.hic": "1206699207,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd25f24df-0298-4079-bb6f-1c8e1e32392b%2F4DNFIGW55EAY.hic": "1033358104,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc57a7ab5-2778-4fe2-9d64-9a4e95135f12%2F4DNFISZRU6I4.hic": "1539570262,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd89f494a-27ca-46b6-89be-7583f3f6d18c%2F4DNFIBKKDTAP.hic": "350372180,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F06725280-cb24-4f22-a267-18b434bfa79e%2F4DNFIJSRQW24.hic": "314361467,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8fe5c3c5-006f-425b-ac6d-ea475e29be78%2F4DNFI3REN89E.hic": "299665258,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc8b6ac5c-13b4-427e-9158-cec0b890b6d8%2F4DNFIF5XLHKM.hic": "235668364,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F01ff33fc-fc23-48aa-a6c6-b720d6fe0768%2F4DNFIB6Q538N.hic": "316494853,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F552978c8-abbc-424a-9187-3e34cf802a97%2F4DNFI3ST3F7Y.hic": "296298411,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F72e66520-5170-4f2e-844a-fbfd0e23b480%2F4DNFIQT4M6UI.hic": "307691265,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb3eb3953-ffe8-4d5a-970c-a16f3b7b9ec3%2F4DNFIFNCC7M2.hic": "364160436,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F37d413fb-afab-41ff-99aa-1678830f7dde%2F4DNFITM9MDJL.hic": "252412426,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faf5db92c-aaf1-4086-a0d2-79b28648f172%2F4DNFI3NTD7B3.hic": "227586640,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff6892f7b-1f8a-4d7d-9605-c67932cd5654%2F4DNFIBED48O1.hic": "328344868,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd64afb07-5ab1-4c4f-8438-2fee37afa86a%2F4DNFISATK9PF.hic": "379669025,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fad11bb63-8692-461c-b698-89c601451321%2F4DNFISRP84FE.hic": "298491537,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F63710ec5-35d2-47dd-bf9a-08d3daa83d67%2F4DNFI2LY7B73.hic": "303150299,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F76077d9e-ca75-4301-ab79-88aa49bda495%2F4DNFIXEB4UZO.hic": "711757090,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F34dd4c0e-3487-4fb4-b95f-c4bb66e0fa31%2F4DNFIQWWATGK.hic": "221304476,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F48a8cf86-3248-4a8e-8e85-472ec219d49c%2F4DNFI44JLUSL.hic": "284772724,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fee399fbf-2a9e-46e2-8364-457713bff508%2F4DNFIM7Q2FQQ.hic": "330729464,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F124d191a-c5c7-49b6-b84b-0d1bad8bef2b%2F4DNFIZK7W8GZ.hic": "293166018,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe351f7cc-7a2c-4515-ae0b-3bb2f91c986a%2F4DNFIMIMLMD3.hic": "240094740,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffd1ae249-57b1-41d6-81a6-16b21dcd8f12%2F4DNFI7QUSU5J.hic": "681762505,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F46c92c5c-9ad8-4d49-beba-f35c5cd99208%2F4DNFINXUOI5H.hic": "375594313,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd7350d40-4e9e-4131-9151-bb539d39b431%2F4DNFIIJR2296.hic": "331322302,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbfacf37b-1665-45f3-a046-e29e1544e9b0%2F4DNFIJMZUTXR.hic": "361313244,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F234e18fb-9990-43bf-9a48-f72722cfa232%2F4DNFIZQPT9EL.hic": "540421873,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4b35ab09-2e79-4876-b2bc-d3a8faa26257%2F4DNFIZIXSZ9J.hic": "545066813,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2ae5118e-807a-46e0-8e92-143d4b282b3d%2F4DNFIDD9IF9T.hic": "237409149,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb34d7f6f-73f7-4b6b-80c7-c8af0f991549%2F4DNFIVC8OQPG.hic": "355121712,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9ab169fd-cbdd-4f60-8553-a4c6912ad89e%2F4DNFITUPI4HA.hic": "313085022,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4e53712b-d0d9-40c5-afe1-ffa0fad71e59%2F4DNFI5IZNXIO.hic": "341863906,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7a91477f-45eb-4a16-affb-fc131f9326b8%2F4DNFII16KXA7.hic": "248904495,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F153729de-0eca-43cc-9b61-61aa8d775b38%2F4DNFIAAH19VM.hic": "668047244,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F879fd78a-7165-43d9-a0de-ca158b53df30%2F4DNFIVKIY3KV.hic": "247571318,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F67f901a4-040b-4b04-9fff-94a1780423cd%2F4DNFIVVCWL6C.hic": "198355985,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd5b30c5a-654a-463c-9474-39bdc3ce4500%2F4DNFIJRF2273.hic": "343971277,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7070280a-8f03-40d0-9fef-e9d9f4d3dae7%2F4DNFI6FCC9ZA.hic": "312013061,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F47049719-9407-4d76-9048-26231045f642%2F4DNFI1CPQ4O3.hic": "352923267,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F862c2b5f-44c2-4bd7-9264-b3ca032d3d8d%2F4DNFISXFEDE9.hic": "271745977,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Feffbe052-39ff-4217-9871-44199ed90818%2F4DNFIF9L6QEQ.hic": "668409334,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa7791854-1b35-4258-90fb-603c55f24642%2F4DNFIPKASX1Q.hic": "312727920,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F79cccad6-a78e-4de9-9fe7-08f7e8df663d%2F4DNFIWXZ4JQZ.hic": "151808553,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7e86e0cc-d6f1-4ce0-aba4-c36c6ebb3c4c%2F4DNFIEMQ1O8V.hic": "700533280,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4a1b58d3-9ae6-43e4-91cf-49f1fcbbab33%2F4DNFIYWONU7A.hic": "729050356,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F67bff264-0b0c-4c5c-9d4d-ff5abef2bbe4%2F4DNFISNIE5FH.hic": "7987657997,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3a6a943f-2cc0-4433-a27d-c89f3fdd3309%2F4DNFIV8MLWJB.hic": "6646216171,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9e235711-ba90-404d-b4ac-b15990ea8e33%2F4DNFIXSTDPHL.hic": "3146468238,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0c89f4bc-e9fc-4ecf-b868-26108dbb7bb2%2F4DNFIQJNOH8U.hic": "2919703928,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1856ff9e-de7a-4fa1-ae0b-3e04b837b5e1%2F4DNFI57XB4A8.hic": "13417594377,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F099bb5b9-6315-4650-8f78-d603f5a046b3%2F4DNFIQ7UKVZV.hic": "9016468637,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faf3c7a30-0fc4-410d-8d41-247da3eb105d%2F4DNFIWJY8GHD.hic": "7921314766,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fda99f429-20ab-4d91-bbfe-f8bdea8ed7b1%2F4DNFIN8NKRNF.hic": "4247121358,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0ca0f4e3-30ca-4332-b735-e32dce08e1a4%2F4DNFIZYGEHNZ.hic": "4910404663,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F243f48b0-6b59-4f37-ad39-a12e0986f530%2F4DNFIRPYGAV8.hic": "4220073636,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F49f1a890-d458-42a6-9c78-99c38b6c7a8f%2F4DNFI6MZTDM1.hic": "375035361,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F76bd4768-9343-44ba-a99d-5500e184e39d%2F4DNFIG3BCGPL.hic": "2422923206,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F9d1ed866-eaf6-4906-aa8c-9e39616dde38%2F4DNFIS9YPJ3L.hic": "1593271574,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1e7a5636-bdb6-45fb-85d8-87a7e908de43%2F4DNFIOWGA9AX.hic": "1632314387,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5ecc552a-0a6b-49a9-9aa5-9f3fd9b535fd%2F4DNFINKEDSBF.hic": "1458761024,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6c03f1d2-bb25-4c94-b5f5-d5cdd01c14b6%2F4DNFIVIXVUK7.hic": "1504818271,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1a4616ff-6d04-4a6a-9773-b80e81da2471%2F4DNFIANHFY16.hic": "1850724180,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd2231870-a78d-4c8b-9cfd-084f2468acb1%2F4DNFIJ67ZFBI.hic": "1473864703,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F859c2dd3-df8a-42b7-86ea-bf6524f75e80%2F4DNFIG3YQQ4V.hic": "1539822829,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8bad667e-6003-4828-9a20-07585fa9c811%2F4DNFIOLKEOME.hic": "3036353747,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F92ea551b-5779-4d31-a0f5-621e4bd0eb21%2F4DNFIVLN8WJE.hic": "2710426318,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F61ee79fa-0617-4083-b121-f92ef3a8905c%2F4DNFI2111XQA.hic": "2589549183,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F83ebb98d-e5db-403d-aa23-875293156c0e%2F4DNFI88HXX7D.hic": "2462367030,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6d63671a-8f1d-4159-82c0-931b43464772%2F4DNFIUBM6QK8.hic": "2486020469,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcccb9014-ce15-4032-81ac-222b3d621cb4%2F4DNFIVILEERC.hic": "2052537210,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F21a07867-4007-4ca5-b066-25d3168208dc%2F4DNFIF7Q43N4.hic": "2091773428,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff738de0f-358b-44bf-97dd-644c8d9b1b1c%2F4DNFID56VJGW.hic": "878913568,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fda97c1d4-bb92-4a77-92a7-f7960f88b9dc%2F4DNFIYUR29KO.hic": "983915575,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F54ea363a-99e7-4f55-861e-1644acab7939%2F4DNFIUDPGHO8.hic": "905253594,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdcd66a07-55ee-48d9-a157-419cf4ee3eef%2F4DNFISWC2N8T.hic": "1233576358,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F40363837-7466-4a1d-b20d-a66d1670b700%2F4DNFICMPHDMI.hic": "516590777,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0b3fa4e6-caeb-40b4-8942-e9c0b03dfe4f%2F4DNFIKMKN736.hic": "634310295,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb6a34050-d52b-4880-91cf-d3fe4b0ce982%2F4DNFIN5NLS49.hic": "650451131,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8e63a47b-9fd4-4772-8b0c-10ee589ffb16%2F4DNFI4P8UBRL.hic": "705655033,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7d92b666-30cc-4838-9d46-c1f11cee3d3c%2F4DNFISNC3LAI.hic": "176146197,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F17f7dc63-a1bc-4970-b389-87d9ee93b753%2F4DNFIVBWPFD7.hic": "749678512,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff8897333-4791-4a83-9444-a653e24aee13%2F4DNFITMQRXB7.hic": "254477361,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8712bf31-33d0-4562-ab0b-a1e9cf4fd30c%2F4DNFIUYD3HBY.hic": "995625724,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbfaf6383-9cc6-4b5c-9b20-d3bf2e8c3495%2F4DNFIRRSC7P9.hic": "1149985362,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb0b70598-5d17-4e0a-8831-6cbe697e69e2%2F4DNFIWKR8FP6.hic": "1151628407,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fccc094a8-23ce-4fd8-9c4a-41f07c045c8f%2F4DNFIQ74RSUY.hic": "931695969,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8eda16f3-af5e-4f1f-b46d-c74df44b76aa%2F4DNFI9IIO5PW.hic": "1077740364,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff283637b-cf2c-4412-ba00-0e3d7e279fad%2F4DNFI8WGJG19.hic": "812086456,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7ad8438f-d3d9-42d8-88bf-42845a0dc2d0%2F4DNFIQ5CCESJ.hic": "652559720,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F70e3bdcc-caf4-4822-b9c5-85234873db7a%2F4DNFIWTZHB4H.hic": "760694196,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F56ed767d-cd8a-4d38-8047-ed1e8bc8cd19%2F4DNFIENYQCDP.hic": "1159428005,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa912fff9-5f90-42b1-a3a6-cbb9eb2cf861%2F4DNFIFATZWPD.hic": "1033325746,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F21338ea5-fdee-4ad8-b61c-608fc6cec4bc%2F4DNFI7YRXKEO.hic": "388142539,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff0f88647-5631-437b-b48a-4603915e3714%2F4DNFIUY3J7EW.hic": "407344788,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F16926af8-ac3b-4f32-97d5-6c0dc6f1596a%2F4DNFIRKUYKP4.hic": "893352368,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F93e39aec-6cde-4c5b-bcdf-283faa3163a2%2F4DNFIAQDBJZP.hic": "489922022,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0dc0b1ba-5509-4464-9814-dfe103ff09a0%2F4DNFIC1CLPK7.hic": "565748333,37769",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fb50964c7-b025-49d9-b1ed-289bedb59678%2F4DNFI3A3VYWU.hic": "4597459343,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff4390cea-b330-4022-88c1-9ff8c0a786f7%2F4DNFI6JJQKFJ.hic": "3678393678,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F70c0f730-5da1-4863-9b7a-6cdd31066cff%2F4DNFIK61ILGM.hic": "3324300994,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F14b14ac4-ab59-4754-9c43-eacd9d0638e2%2F4DNFIYD8LO9Z.hic": "3227227120,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7386f953-8da9-47b0-acb2-931cba810544%2F4DNFIOTPSS3L.hic": "253942497,7557",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff6b5d8bd-27dc-4415-a320-53405a16db85%2F4DNFI525PKU5.hic": "305089653,7557",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1f922dbc-637b-4c43-b816-f604f0e79ad1%2F4DNFI8Y9SRP2.hic": "1438589609,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6a36d686-716c-4ba8-a9c3-fa8311791d3c%2F4DNFIP25GZGA.hic": "1419100109,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0e535542-2cf2-46e4-ba10-7a0b946a7fb2%2F4DNFITPO1WTY.hic": "3885296184,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0c17d228-5052-4c83-880e-af05118e6929%2F4DNFIG4X5Z5F.hic": "2159245890,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc3977c00-525b-4556-9a27-061707f8cb76%2F4DNFIXLYR32S.hic": "587852728,21916",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3f856506-cbc5-4211-a29d-48a7530c622e%2F4DNFIWI3V5LI.hic": "2318237793,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F38f77979-5eb7-4b6f-9a60-c2451185db98%2F4DNFI2DGL7LH.hic": "2068150664,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe0992a75-7727-405e-95ac-aa97141b51c1%2F4DNFI1VSO8JP.hic": "1807949651,21916",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe925f1aa-2e7b-44e7-bfa8-2084a613ab13%2F4DNFIV5O2ZYO.hic": "2242134377,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F803a6d3c-3972-4939-8ff2-d195c5192090%2F4DNFIEWEWQ8D.hic": "2981341325,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F25104375-a588-46e6-a382-663cee6c332f%2F4DNFICSTCJQZ.hic": "14592996888,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F927aa8a5-24a5-43b7-807a-1e99ac321459%2F4DNFICEGAHRC.hic": "15431307603,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8c380474-12cd-41f4-b86f-2a29faf12eec%2F4DNFIY3XPKPO.hic": "2550999291,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F77da562e-d0ec-45a8-8764-14fa16d995a1%2F4DNFIK9PS9GM.hic": "3401011629,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F11b26222-d480-40a7-8d53-3ad8500396cb%2F4DNFIF4Y74JR.hic": "2127593378,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F93df02c6-1047-43f6-afae-d3598b61eeea%2F4DNFIBBKG9KD.hic": "5724446278,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6cd4378a-8f51-4e65-99eb-15f5c80abf8d%2F4DNFIT4I5C6Z.hic": "5419655766,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F067881e5-563d-40de-a9e3-caccad887fbc%2F4DNFI2U38HCE.hic": "14749043471,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fcb3da9ad-7afe-4d0c-9c2d-261181e7a95c%2F4DNFIETP228R.hic": "9401474797,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F185b7bcc-7bb7-41b7-9ebe-92d4fe159431%2F4DNFIXKC48TK.hic": "11624295594,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F18459b84-dd43-4af6-a05f-bcb773096322%2F4DNFIFPGOWJ6.hic": "2843730862,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffde1e48c-4a0d-4bb1-b84a-e0e45f9a0aa3%2F4DNFIIRMBE8Y.hic": "5820677927,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbabed539-1f0f-4e87-8815-fde596e6024c%2F4DNFIVUGNDD7.hic": "5170782436,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7d00531a-e616-469b-af52-5b028270e2ce%2F4DNFIFLJLIS5.hic": "18917549985,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7a9dad53-b8af-4cb6-9ce3-5c38e4a855c7%2F4DNFIOX3BGNE.hic": "5482775936,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F82fd7b7e-5f47-48d5-bf0d-daaa48a4674d%2F4DNFISPUVQHA.hic": "2177864938,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4c6680f3-9cf6-452f-9dc5-ecebf376775d%2F4DNFI8649RQJ.hic": "3288850368,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2395f001-19d9-4000-9f00-dae3dea708d6%2F4DNFIE8T8JZ8.hic": "1383191843,21916",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F24464fa8-bf96-483c-8e9a-c2f626ad6e19%2F4DNFIBM714BP.hic": "1765204569,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F918fc048-fa27-41d2-a63a-d50c511fbb9a%2F4DNFI6CWNUFL.hic": "2431120602,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7ff5a0d1-8e54-4cf0-9fe7-339f147c78da%2F4DNFIZ4F74QR.hic": "3211825957,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F355f0412-cb17-452a-b15c-aba16c5a742c%2F4DNFIOUVOX3R.hic": "5547401083,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3cac91c5-160a-4b91-a91e-edc309318dd5%2F4DNFIVTDXW6X.hic": "4797344498,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe9ac9ec5-6221-4f39-951a-1c5054fc9f1c%2F4DNFICA8Y9TN.hic": "4277557869,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F46f62222-b50a-428b-938b-5444951600eb%2F4DNFIDWM3HN5.hic": "4374808431,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F50e4f2a1-9cb6-4192-8199-64d354bfdf96%2F4DNFIBK5TB3N.hic": "3757387253,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe4cdd5a8-1a45-4c6a-906b-a9603260d4c8%2F4DNFI3JYF9VS.hic": "5260534916,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc8164e99-5778-427d-be26-f0e1b62400f0%2F4DNFIP1GNKUO.hic": "7589365689,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0d72e78a-fc87-4716-8b8e-6dc5650ff2ef%2F4DNFIQYQWPF5.hic": "22022259901,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fac58fc15-48c2-4eec-a689-23b677b4b6e7%2F4DNFIW6H9U3S.hic": "6810897169,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F31811e40-e3ca-4456-8ba6-2ee66d6c7104%2F4DNFI7J8BQ4P.hic": "7188744676,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F62d81a52-d2cb-4bbd-8510-0fe120e786e0%2F4DNFIA3AKFSU.hic": "1763730692,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fae0b7513-d1a0-4f27-a9d3-348837f42cb6%2F4DNFID68JQY9.hic": "2422880133,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fae6a1a7e-1d2d-4fd2-a89f-1799abac8a9c%2F4DNFIL1FQDXE.hic": "1543814834,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F80d343c9-bd60-468a-803d-c3e351a958bc%2F4DNFITSIPCSK.hic": "2344682115,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff2d61195-a3a6-4d50-b928-546ca67546cb%2F4DNFIJ88LYHR.hic": "3362742962,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F31052aba-773a-4500-961b-e0a518245b0c%2F4DNFIJIPWD63.hic": "2529548812,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc93d0710-3fbf-40cc-b0eb-878e2aa659e4%2F4DNFIZUP2BBI.hic": "491996147,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F8660baeb-5be1-4ba5-93e4-66499e9a4587%2F4DNFIEBC4AF3.hic": "2993048734,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff6d08fbc-b836-4776-82ad-8b1b277ae837%2F4DNFI5VO3E1W.hic": "1949322679,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3af004a2-a6fa-46dc-8be8-898b847a77be%2F4DNFIFDGVWLU.hic": "2456400803,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbd613b4b-2422-492c-8d8f-8afba9c8a182%2F4DNFITVGFNYZ.hic": "1411204423,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc340694f-14f3-4638-b09b-98220c20039a%2F4DNFIOW9TTC1.hic": "2611370588,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3effa71b-6aa3-4265-87ba-f8d709d778d7%2F4DNFIOAA3ZEQ.hic": "3123195980,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fde75cf8b-9ce1-44fc-8b7b-ca0adea6bae9%2F4DNFIA5ID1S6.hic": "3496246968,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F714f775d-a5a4-4314-83f8-016b9d73200b%2F4DNFIZYU7V81.hic": "4245592042,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5ca518af-6e1e-4777-b67b-0fe7f039ce58%2F4DNFIRV6PVUX.hic": "3981520466,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc29b57d5-c263-4372-b396-ab4f55682f5d%2F4DNFIMEANFBY.hic": "3999623376,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4196d3ef-161a-4c18-a57e-005165c6b32a%2F4DNFIGLGQXLC.hic": "4105683321,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe10828b5-9519-4ceb-81e7-7228bfd890de%2F4DNFILS2HLXC.hic": "3804942111,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Feaac88c7-9bdc-4b1e-aa9f-e675520dfffa%2F4DNFIQS8853L.hic": "5287726781,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faab371b1-bc8d-4e83-998e-5bb977c443ca%2F4DNFIVBYCYGS.hic": "4657988157,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdb116add-af8c-4a3b-b106-9f704ba0a175%2F4DNFIE4WWHMF.hic": "5116727268,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F033366e2-05a2-4381-9d48-90122892a9e0%2F4DNFIKDUUC3C.hic": "45638237,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F7284a446-442d-4a46-aae5-84987e75a7af%2F4DNFIJ5HIRA2.hic": "62201052,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F876649a3-6dd3-463a-8b17-5752a98a89c5%2F4DNFIXDFC7EM.hic": "69878143,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3cb4ce4d-d474-459f-8b8a-2159d8df8068%2F4DNFIO35EOVR.hic": "70153114,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5c5612fa-18c2-47fb-8074-693b1054e254%2F4DNFI57QGL6U.hic": "54605055,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F70c1472d-cf3a-41d7-8682-cd03b7cc978d%2F4DNFI2AGEBE5.hic": "67930121,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2bf4cce8-6e03-422b-a190-e51d4a07d501%2F4DNFI1E6NJQJ.hic": "4893181511,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0eb3aa56-8a23-435d-a07b-8bb545e8d708%2F4DNFI2DNFM1A.hic": "60466977,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F1201682a-a223-482d-913d-3c3972b8eb65%2F4DNFIIRIHBR2.hic": "48961678,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F6aee7913-6356-43cf-affa-f2b60dd478f6%2F4DNFIKXNMT46.hic": "39095246,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F357139a3-c9a5-4343-b0a4-b41c45fd03fd%2F4DNFIRV7IRJP.hic": "70916718,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F76be3061-6259-42be-a917-19dcba41517b%2F4DNFIFX1XYKZ.hic": "229197441,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ff60c348a-a62c-43c3-ac85-f6c28b5f823f%2F4DNFI64CJUZ5.hic": "112428610,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fbd0b0a14-9703-49af-bb36-2359f96d4d0e%2F4DNFIBPL2HL1.hic": "73972428,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F79ac278d-83f7-4c1f-91b2-05c8018c1426%2F4DNFIBRSIPE4.hic": "415430378,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F0c3b8141-8e67-4211-80dd-3bae88f9e537%2F4DNFIDLDVY14.hic": "112747303,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2ae703d2-d85c-4ac4-9ce1-14d653927675%2F4DNFI4R1II4P.hic": "210565448,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F76e641af-90be-49f8-b126-12c20f856159%2F4DNFINMLRY95.hic": "1590865292,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3b416382-ae5d-469f-9e9a-85a3e2ce9793%2F4DNFI8KBXYNL.hic": "4626496956,22663",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa27589a6-86b2-43a2-8218-1f2232d551e6%2F4DNFI53R5IKA.hic": "1536016455,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdd3999f5-b36e-4154-a6b9-446656959a3a%2F4DNFIAWVDQ8C.hic": "2210273411,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fc377953b-0e61-426b-a905-abab2d5a3002%2F4DNFI9VXTBE2.hic": "3384365692,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F2dbe0377-1db2-4aae-af2b-d87dff728035%2F4DNFIT96Z365.hic": "7692311974,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fdcfcb009-f006-4ab8-a4c7-af72be58c12c%2F4DNFITUOMFUQ.hic": "6592120312,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F67f9073d-b2c5-474e-b3d5-7d72cde555cd%2F4DNFIHLQKDN8.hic": "2647703964,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F76968cc8-fb9f-485b-85b2-c9289067194b%2F4DNFIL9M97T2.hic": "6186798917,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F87d9330b-8d2c-4e9d-85bb-02e572de7a68%2F4DNFIDWGZLHX.hic": "4379708168,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Ffc01b381-3665-4225-88ee-2e5e9ecb7324%2F4DNFIMEG1QIC.hic": "3587146533,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3144a03c-15c3-4bf9-95f8-ffad2640a1bf%2F4DNFIWSB66VX.hic": "1529739069,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fd7d3aac0-ba66-494b-ba0c-147631084b98%2F4DNFIH7TH4MF.hic": "6896300679,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F4b13bd19-fbd1-4c68-8855-4f0158d1ce47%2F4DNFIHKWIZ9T.hic": "1954878455,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F3d04e286-30d4-4add-a806-7a929d36419e%2F4DNFIZBBX795.hic": "1350142414,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Faa26f261-a88a-4cac-9118-ff8e90ab6f61%2F4DNFI9ZWZ5BS.hic": "3410552807,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F24e516ce-0b49-4476-b818-cc96471d8765%2F4DNFIPAI8XB5.hic": "4168518737,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fac3b06b5-46d4-4c1e-97e9-9a4f353473a7%2F4DNFI1OUWFSC.hic": "10766406904,21916",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F76c6aa27-895f-4e82-8ed8-7a85aca84490%2F4DNFIX4PJKXZ.hic": "1463157350,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fe6fbe03a-997b-4d72-a3cc-f1ed7bd88a92%2F4DNFIZGCA8AH.hic": "1597664180,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F61d99a87-4ec6-4116-be99-b483999641c4%2F4DNFI5CGQ46V.hic": "2870576870,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F271ea8cd-53b9-44c9-9fff-1bb5c444b54c%2F4DNFIOCW5AEM.hic": "2463600138,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2Fa98ca64a-861a-4a8c-92e9-586af457b1fb%2F4DNFI1UEG1HD.hic": "22480709268,25900",
    "4dn-open-data-public.s3.amazonaws.com%2Ffourfront-webprod%2Fwfoutput%2F5809b32e-0aea-4cf5-a174-cf162d591a35%2F4DNFI9YAVTI1.hic": "3141182061,25900"
}